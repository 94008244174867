import {
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client/react/hooks';
import {
  CreateStoreInput,
  Organization,
  Store,
  UpdateStoreDetailsInput,
  UpdateStoreInput,
  UpdateStoreProfitMarginsInput,
  Venue,
} from '@oolio-group/domain';
import {
  CREATE_STORE,
  DELETE_STORE,
  GET_MINIMAL_STORES_QUERY,
  GET_STORES_QUERY,
  GET_STORE_BY_ID_QUERY,
  GET_STORE_VENUE_ORG_SLUGS_BY_STORE,
  UPDATE_STORE,
  UPDATE_STORE_DETAILS,
  UPDATE_STORE_PROFIT_MARGINS,
} from '../../graphql/store';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';
import { MutationResult } from '@apollo/client';
import { useSession } from './useSession';

export interface UseStores {
  loading: boolean;
  error?: string;
  stores: { [key: string]: Store };
  minimalStore: { [key: string]: Store }; // minimal (getting just limited data.. store ID && NAME)
  createdStoreId: string | undefined;
  updatedStoreId: string | undefined;
  deletedStore: boolean | undefined;
  getStores: () => void;
  getMinimalStores: () => void;
  createStore: (input: CreateStoreInput) => void;
  updateStore: (input: UpdateStoreInput) => void;
  updateStoreProfitMargins: (input: UpdateStoreProfitMarginsInput) => void;
  updateStoreDetailsPage: (input: UpdateStoreDetailsInput) => void;
  updateStoreDetailsResponse: MutationResult<Store>;
  deleteStore: (id: string) => void;
  getSlugsByStore: (storeId: string) => void;
  slugs: {
    organization: string;
    venue: string;
    store: string;
  };
}

interface Props {
  venueId?: string;
  storeId?: string;
}

export const useStores = (props?: Props): UseStores => {
  const { storeId } = props || {};
  const [stores, setStores] = useState<Record<string, Store>>({});
  const [minimalStore, setMinimalStores] = useState<Record<string, Store>>({});
  const [createdStoreId, setCreatedStoreId] = useState<string | undefined>();
  const [updatedStoreId, setUpdatedStoreId] = useState<string | undefined>();
  const [deletedStore, setDeletedStore] = useState<boolean | undefined>(false);
  const [session, setSession] = useSession();
  const [slugs, setSlugs] = useState({
    organization: '',
    venue: '',
    store: '',
  });
  const [deletedStoreId, setDeletedStoreId] = useState<string | undefined>(
    undefined,
  );

  const client = useApolloClient();

  // get store
  const onCompleteGetStoreRequest = useCallback(data => {
    if (data) {
      const storeData = data.store as Store;
      setStores(stores => ({
        ...stores,
        [storeData.id]: { ...stores[storeData.id], ...storeData },
      }));
      setDeletedStore(undefined);
    }
  }, []);

  const [getStoreRequest, getStoreResponse] = useLazyQuery(
    GET_STORE_BY_ID_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetStoreRequest,
      onError: noopHandler,
    },
  );

  const onCompleteStoreSlugRequest = useCallback(data => {
    if (data) {
      const storeData = data.store as Store;
      const venueData = storeData.venue as Venue & {
        organization: Organization;
      };
      const orgData = venueData.organization;
      if (orgData?.urlFriendlyName && venueData?.slug && storeData?.slug) {
        setSlugs({
          organization: orgData?.urlFriendlyName,
          store: storeData?.slug,
          venue: venueData?.slug,
        });
      }
    }
  }, []);

  const [getSlugsByStoreRequest, getSlugsByStoreResponse] = useLazyQuery(
    GET_STORE_VENUE_ORG_SLUGS_BY_STORE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteStoreSlugRequest,
      onError: noopHandler,
    },
  );

  const getSlugsByStore = useCallback(
    (storeId: string) => {
      getSlugsByStoreRequest({ variables: { storeId } });
    },
    [getSlugsByStoreRequest],
  );

  useEffect(() => {
    if (storeId) {
      getStoreRequest({ variables: { id: storeId } });
    }
  }, [getStoreRequest, storeId]);

  // get stores
  const onCompleteGetStoresRequest = useCallback(data => {
    if (data) {
      setStores(keyBy(data.me.stores, 'id'));
    }
  }, []);

  const [getStoresRequest, getStoresResponse] = useLazyQuery(GET_STORES_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onCompleteGetStoresRequest,
  });

  // on complete set data to stores
  const onCompleteGetMinimalStoresRequest = useCallback(data => {
    if (data) {
      setMinimalStores(keyBy(data.me.stores, 'id'));
    }
  }, []);
  // get just id and name in stores data
  const [getMinimalStoresRequest, getMinimalStoresResponse] = useLazyQuery(
    GET_MINIMAL_STORES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetMinimalStoresRequest,
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (storeId) {
      getStoreRequest({ variables: { id: storeId } });
    }
  }, [getStoreRequest, storeId]);

  // create store
  const [createStoreRequest, createStoreResponse] = useMutation(CREATE_STORE, {
    onError: noopHandler,
  });

  const [updateStoreDetails, updateStoreDetailsResponse] = useMutation(
    UPDATE_STORE_DETAILS,
    {
      onError: noopHandler,
      onCompleted: response => {
        const updatedStore = response?.updateStoreDetails as Store;
        if (updatedStore?.id === session.currentStore?.id) {
          setSession({
            ...session,
            currentStore: {
              ...session.currentStore,
              businessIdentifier: updatedStore.businessIdentifier,
              address: updatedStore.address,
            },
          });
        }
      },
    },
  );

  const updateStoreDetailsPage = useCallback(
    (input: UpdateStoreDetailsInput) => {
      updateStoreDetails({ variables: { input } });
    },
    [updateStoreDetails],
  );

  useEffect(() => {
    if (createStoreResponse.data) {
      const newStore = createStoreResponse.data.createStore;
      setStores(stores => ({ ...stores, [newStore.id]: newStore }));
      setCreatedStoreId(newStore.id);
    }
  }, [createStoreResponse.data]);

  const createStore = useCallback(
    (input: CreateStoreInput) => {
      createStoreRequest({ variables: { input } });
    },
    [createStoreRequest],
  );

  // update store
  const [updateStoreRequest, updateStoreResponse] = useMutation(UPDATE_STORE, {
    onError: noopHandler,
  });

  useEffect(() => {
    if (updateStoreResponse.data) {
      const store = updateStoreResponse.data.updateStore;
      setStores(stores => ({ ...stores, [store.id]: store }));
      setUpdatedStoreId(store.id);
    }
  }, [updateStoreResponse.data]);

  const updateStore = useCallback(
    (input: UpdateStoreInput) => {
      updateStoreRequest({ variables: { input } });
    },
    [updateStoreRequest],
  );

  // update store profit margins
  const [updateStoreProfitMarginsRequest, updateStoreProfitMarginsResponse] =
    useMutation(UPDATE_STORE_PROFIT_MARGINS, {
      onError: noopHandler,
    });

  useEffect(() => {
    if (updateStoreProfitMarginsResponse.data) {
      const store =
        updateStoreProfitMarginsResponse.data.updateStoreProfitMargins;
      setStores(stores => ({ ...stores, [store.id]: store }));
      setUpdatedStoreId(store.id);
    }
  }, [updateStoreProfitMarginsResponse.data]);

  const updateStoreProfitMargins = useCallback(
    (input: UpdateStoreProfitMarginsInput) => {
      updateStoreProfitMarginsRequest({ variables: { input } });
    },
    [updateStoreProfitMarginsRequest],
  );

  // delete
  const deleteStoreFromCache = useCallback(() => {
    const cachedStores = (
      client.cache.readQuery({
        query: GET_STORES_QUERY,
      }) as { me: { stores: Store[] } }
    ).me.stores;

    const updatedStores = cachedStores.filter(
      store => deletedStoreId !== store.id,
    );

    client.cache.writeQuery({
      query: GET_STORES_QUERY,
      data: {
        me: { stores: updatedStores },
      },
    });
  }, [client.cache, deletedStoreId]);

  const [deleteStoreRequest, deleteStoreResponse] = useMutation(DELETE_STORE, {
    onError: noopHandler,
  });
  useEffect(() => {
    if (deleteStoreResponse.data) {
      setDeletedStore(deleteStoreResponse.data.deleteStore);
      deleteStoreFromCache();
    }
  }, [deleteStoreFromCache, deleteStoreResponse]);

  const deleteStore = useCallback(
    (id: string) => {
      deleteStoreRequest({
        variables: {
          id,
        },
      });
      setDeletedStoreId(id);
    },
    [deleteStoreRequest],
  );

  const loading =
    createStoreResponse.loading ||
    updateStoreResponse.loading ||
    updateStoreProfitMarginsResponse.loading ||
    deleteStoreResponse.loading ||
    getStoresResponse.loading ||
    getMinimalStoresResponse.loading ||
    getStoreResponse.loading ||
    getSlugsByStoreResponse.loading;
  const error =
    createStoreResponse.error ||
    updateStoreResponse.error ||
    updateStoreProfitMarginsResponse.error ||
    deleteStoreResponse.error ||
    getStoresResponse.error ||
    getMinimalStoresResponse.error ||
    getStoreResponse.error ||
    getSlugsByStoreResponse.error ||
    updateStoreDetailsResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      stores,
      minimalStore,
      createdStoreId,
      updatedStoreId,
      getStores: getStoresRequest,
      getMinimalStores: getMinimalStoresRequest,
      createStore,
      updateStore,
      updateStoreProfitMargins,
      updateStoreDetailsPage,
      updateStoreDetailsResponse,
      deleteStore,
      deletedStore,
      getSlugsByStore,
      slugs,
    }),
    [
      loading,
      error,
      stores,
      minimalStore,
      createdStoreId,
      updatedStoreId,
      getStoresRequest,
      getMinimalStoresRequest,
      createStore,
      updateStore,
      updateStoreProfitMargins,
      updateStoreDetailsPage,
      updateStoreDetailsResponse,
      deleteStore,
      deletedStore,
      getSlugsByStore,
      slugs,
    ],
  );
};
