import { useCallback, useEffect, useRef, useState } from 'react';
import {
  App,
  DeviceProfile,
  IntegrationPartner,
  Order,
  OrderAction,
  OrderPaymentEvent,
  OrderPaymentStatus,
  OrderStatus,
  OrderTypeCode,
  RESERVATION_STATUS,
  Reservation,
} from '@oolio-group/domain';
import { POS_IDENTIFIER } from '../../../constants';
import { useSession } from '../useSession';
import { useApolloClient } from '@apollo/client/react/hooks';
import { GET_INTEGRATION_PARTNERS } from '../useIntegrationPartners/graphql';

import { useOrderTypes } from '../orderTypes/useOrderTypes';
import { useOrders } from '../orders/useOrders';
import { useCustomers } from '../../orders/useCustomers';
import { formatEmail, formatPhone } from '../../../utils/customer';
import { createNewOrderStore } from '../../../store/OrderStore';
import { useCart } from '../../../hooks/orders/useCart';
import { nanoid } from 'nanoid';
import { usePaymentTypes } from '../usePaymentTypes';

export interface ReservationFilters {
  date?: string;
  searchText?: string;
  statuses?: RESERVATION_STATUS[];
}

export const useReservations = () => {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [session] = useSession();
  const client = useApolloClient();
  const [integrationPartner, setIntegrationPartner] = useState<
    IntegrationPartner | undefined
  >();
  const { getOrderTypes, orderTypes } = useOrderTypes();
  const { returnOrdersFromCache } = useOrders();
  const { addNewCustomer, getCustomerByEmailOrPhone } = useCustomers();
  const { paymentTypes } = usePaymentTypes();
  const orderStoreRef = useRef(createNewOrderStore());
  const { resetCart, setCartParams, updateCart } = useCart(
    orderStoreRef.current,
  );

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  const dineInOrderType = Object.values(orderTypes || {}).find(
    orderType => orderType.code === OrderTypeCode.DINE_IN,
  );

  const getIntegrationPartner = useCallback(async () => {
    const result = await client.query({
      query: GET_INTEGRATION_PARTNERS,
      variables: {
        filter: {
          appName: App.OOLIO_RESERVATION,
          store: session.currentStore?.id,
        },
      },
      fetchPolicy: 'network-only',
    });
    const integrationPartner = result?.data?.integrationPartners?.[0];
    if (!integrationPartner) {
      setError('Reservation integration not found for this store');
    }

    setIntegrationPartner(integrationPartner);
  }, [client, session.currentStore?.id]);

  useEffect(() => {
    getIntegrationPartner();
  }, [getIntegrationPartner]);

  const filterReservationsData = useCallback(
    (data: Reservation[], filters: ReservationFilters): Reservation[] => {
      let result = data;

      if (filters.searchText) {
        const searchText = filters.searchText.toLowerCase();
        result = result.filter(reservation => {
          if (
            searchText &&
            (reservation.phone_number.toLowerCase().includes(searchText) ||
              reservation.reference_code.toLowerCase().includes(searchText) ||
              reservation.full_name.toLowerCase().includes(searchText) ||
              reservation.table_numbers
                .map(tableNum => tableNum.toLowerCase())
                .includes(searchText))
          ) {
            return true;
          }
          return false;
        });
      }
      if (filters.statuses?.length) {
        result = result.filter(reservation => {
          if (
            filters.statuses?.some(
              status =>
                status.toLowerCase() === reservation.status.toLowerCase(),
            )
          ) {
            return true;
          }
          return false;
        });
      }
      return result;
    },
    [],
  );

  const fetchReservationsData = useCallback(
    async ({
      fromDate,
      toDate,
      posLocationId,
      organizationId,
    }: {
      fromDate: string;
      toDate: string;
      posLocationId: string;
      organizationId: string;
    }): Promise<Reservation[]> => {
      const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/reservations/${POS_IDENTIFIER}/${organizationId}/${posLocationId}?from=${fromDate}&to=${toDate}`;
      const response = await fetch(url, {
        headers: { Accept: 'application/json' },
      });
      if (!response.ok) {
        throw new Error(
          `Failed to fetch reservations. status: ${response.status}.`,
        );
      }
      return response.json();
    },
    [],
  );

  const fetchReservations = useCallback(
    async (fromDate: string, toDate: string) => {
      const posLocationId =
        integrationPartner?.preferences?.oolioReservation?.posLocationId || '';
      const organizationId = session.currentOrganization?.id || '';

      if (!fromDate || !toDate || !posLocationId || !organizationId) return;

      setLoading(true);
      setError('');

      try {
        const data = await fetchReservationsData({
          fromDate,
          toDate,
          posLocationId,
          organizationId,
        });
        setReservations(data || []);
      } catch (e) {
        console.error('Fetching error:', e);
        setError('Failed to fetch reservations');
      } finally {
        setLoading(false);
      }
    },
    [
      integrationPartner,
      session.currentOrganization?.id,
      fetchReservationsData,
    ],
  );

  // This function generates order events for the given seated reservation
  const _generateOrderEventsForReservation = useCallback(
    async (reservation: Reservation, existingOrder?: Order): Promise<void> => {
      if (!session.currentVenue?.id) throw new Error('Venue not available');

      const sections = (session.deviceProfile as DeviceProfile).sections;

      const matchingSection = sections.find(
        section =>
          section.name.toLowerCase() ===
          reservation.venue_seating_area_name.toLowerCase(),
      );

      if (!matchingSection) {
        throw new Error('Section not found');
      }

      const matchingTables = matchingSection.tables.filter(table =>
        reservation.table_numbers.some(
          t => t.toLowerCase() === table.name.toLowerCase(),
        ),
      );

      if (matchingTables.length !== reservation.table_numbers.length) {
        throw new Error('Table do not match');
      }

      if (existingOrder) {
        // set cart to existing order
        setCartParams(existingOrder?.id, dineInOrderType?.id, undefined, true);
      } else {
        setCartParams(undefined, dineInOrderType?.id);
        // init order
        await resetCart();
      }

      // assigning reservation
      updateCart(OrderAction.ORDER_ASSIGN_RESERVATION, {
        reservation: {
          externalRef: reservation.reference_code,
          notes: reservation.notes,
        },
      });

      // assigning table
      updateCart(OrderAction.ORDER_ASSIGN_TABLES, {
        tables: matchingTables.map(table => ({
          tableName: table.name,
          tableId: table.id,
          guestCount: reservation.max_guests,
          sectionId: matchingSection?.id,
          sectionName: matchingSection?.name,
        })),
      });
      // assign customer
      let customer = await getCustomerByEmailOrPhone(
        formatEmail(reservation.email),
        reservation.phone_number,
      );
      if (!customer && (reservation.email || reservation.phone_number)) {
        const [firstName, ...rest] = reservation?.full_name?.split(' ');
        const lastName = rest?.length ? rest.join(' ') : '';
        try {
          customer = await addNewCustomer({
            email: formatEmail(reservation.email),
            phone: reservation.phone_number,
            phoneNumber: formatPhone(reservation.phone_number),
            firstName,
            lastName,
          });
        } catch (e) {
          console.log('error', e);
        }
      }

      if (customer) {
        updateCart(OrderAction.ORDER_ASSIGN_CUSTOMER, {
          customerId: customer.id,
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          phone: customer.phone,
          isLoyaltyApplied: Boolean(customer?.loyaltyMember),
        });
      }

      // create deposit transaction
      // override if there is any payment event with payment type as sevenrooms
      if (reservation.deposit) {
        const existingTransaction = orderStoreRef.current
          .getSnapshot()
          ?.currentState?.payments?.find(
            payment =>
              payment.paymentType?.name?.toLowerCase() === 'sevenrooms',
          );
        if (!existingTransaction) {
          const paymentRequestId = nanoid();
          const paymentTypeDetail = paymentTypes.find(
            paymentType => paymentType.name.toLowerCase() === 'sevenrooms',
          );
          if (paymentTypeDetail) {
            updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
              tendered: reservation.deposit,
              paymentTypeId: paymentTypeDetail?.id as string,
              tip: 0,
              change: 0,
              roundOffDifference: 0,
              onAccount: false,
              paymentTypeName: paymentTypeDetail?.name as string,
              paymentRequestId: paymentRequestId,
              isPrePayment: true,
              paymentStatus: OrderPaymentStatus.COMPLETE,
            });
          }
        }
      }

      // save order
      updateCart(OrderAction.ORDER_SAVE, {});
      return;
    },
    [
      session.currentVenue?.id,
      session.deviceProfile,
      updateCart,
      getCustomerByEmailOrPhone,
      setCartParams,
      dineInOrderType?.id,
      resetCart,
      addNewCustomer,
      paymentTypes,
    ],
  );

  const createOrderForReservation = useCallback(
    async (
      reservation: Reservation,
    ): Promise<{
      success: boolean;
      message?: string;
      order?: Order;
    }> => {
      try {
        // if there is an order with reservation id, return it - don't create another order
        // assign reservation for that order again if needed
        const openOrders = [
          ...returnOrdersFromCache(OrderStatus.CREATED),
          ...returnOrdersFromCache(OrderStatus.IN_PROGRESS),
          ...returnOrdersFromCache(OrderStatus.ON_HOLD),
        ];
        const existingOrderForThisReservation = openOrders.find(
          order =>
            order.reservation?.externalRef === reservation.reference_code,
        );
        if (existingOrderForThisReservation) {
          return { success: true, order: existingOrderForThisReservation };
        }

        // generate order events
        await _generateOrderEventsForReservation(
          reservation,
          existingOrderForThisReservation,
        );
        return {
          success: true,
          order: orderStoreRef.current.getSnapshot()?.currentState,
        };
      } catch (e) {
        setError('Failed to assign order');
        return {
          success: false,
          message:
            (e as Error)?.message ||
            e?.toString?.() ||
            'Failed to assign order',
        };
      }
    },
    [returnOrdersFromCache, _generateOrderEventsForReservation],
  );

  return {
    reservations,
    loading,
    error,
    fetchReservations,
    createOrderForReservation,
    setReservations,
    filterReservationsData,
    fetchReservationsData,
  };
};
