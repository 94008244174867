import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import {
  ProductModifierGroup,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  ModifierGroup,
  Modifier,
  UpdateProductInput,
  DEFAULT_OPTION_GROUP,
  ModifierType as ModifierTypeAlias,
  ComboItem,
} from '@oolio-group/domain';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { useModifiers } from '../../../../../hooks/app/modifiers/useModifiers';
import { Dictionary, cloneDeep, findIndex, keyBy, orderBy } from 'lodash';
import { PRODUCT_MODIFIERS_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import DraggableFlatList from '../../../../../components/DraggableFlatList/DraggableFlatList';
import { ProductModifierRow } from './ProductModifierRow';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import SelectSearch from '../../../../../components/Shared/Select/SelectSearch';
import theme from '../../../../../common/default-theme';
import styles from './ProductOptions.styles';

enum ModifierType {
  Modifier = 'Modifiers',
  ModifierGroup = 'ModifierGroup',
}

enum ProductType {
  Product = 'Product',
}

interface ProductModifierSectionProps {
  productId: string;
}

export const ProductModifiersSection: React.FC<ProductModifierSectionProps> = ({
  productId,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [productModifiers, setProductModifiers] = useState(
    [] as ProductModifierGroup[],
  );
  const [isComboProduct, setProductComboState] = useState<boolean>(false);

  const updateProductModifiers = (value: ProductModifierGroup[]) => {
    setProductModifiers(value);
  };

  const { currency } = useCurrency();

  const {
    products,
    error: prodErr,
    loading: prodLoading,
    updateProduct,
  } = useProducts(productId, PRODUCT_MODIFIERS_FRAGMENT);

  const loading = prodLoading;

  const error = prodErr;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (productId && products[productId]) {
      const product = products[productId];
      if (product.isCombo && product.comboType) {
        setProductComboState(true);
      }
      if (product?.modifierGroups?.length) {
        const modifierGroups = products[productId]?.modifierGroups.filter(
          x => x && x.id,
        );
        setProductModifiers(modifierGroups);
      }
    }
  }, [productId, products]);

  const onSave = useCallback(() => {
    const modifierGroups = [] as ProductModifierGroup[];
    const defaultModGroup = {
      isGrouped: false,
      isRequired: false,
      modifierGroup: DEFAULT_OPTION_GROUP,
      priority: 1,
      modifiers: [],
      products: [],
    } as unknown as ProductModifierGroup;
    productModifiers.forEach((modGroup, index) => {
      if (modGroup.name === DEFAULT_OPTION_GROUP) {
        defaultModGroup.products?.push(
          ...modGroup?.products?.map(
            (prod, innerIndex) =>
              ({
                id: prod?.id,
                name: prod?.name,
                isDefault: false,
                type: ModifierTypeAlias.PRODUCT,
                price: {
                  amount: 0,
                  currency: prod?.price?.currency || currency,
                },
                priority: index + innerIndex,
              } as unknown as ComboItem),
          ),
        );
      } else {
        modifierGroups.push({
          isGrouped: modGroup.isGrouped,
          isRequired: modGroup.isRequired,
          modifierGroup: modGroup.id,
          priority: index,
          modifiers: modGroup.modifiers.map(eachMod => ({
            id: eachMod.id,
            isDefault: eachMod.isDefault,
          })),
          products: modGroup?.products?.map(
            prod =>
              ({
                id: prod.id,
                isDefault: prod.isDefault,
                name: prod.name,
                price: {
                  amount: prod.price?.amount,
                  currency: prod.price?.currency,
                },
                priority: prod.priority,
                type: prod.type,
              } as ComboItem),
          ),
        } as unknown as ProductModifierGroup);
      }
    });
    defaultModGroup.products?.length && modifierGroups.push(defaultModGroup);
    updateProduct({
      id: productId,
      modifierGroups: modifierGroups,
    } as unknown as UpdateProductInput);
  }, [productModifiers, updateProduct, productId, currency]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${products[productId]?.name} Options | Oolio`}
      onSave={onSave}
    >
      <Section
        title={translate('modifiers.options')}
        subtitle={translate('modifiers.productModifiersDiscription')}
      >
        <ProductModifiers
          productModifiers={productModifiers}
          setProductModifiers={updateProductModifiers}
          productId={productId}
          isCombo={isComboProduct}
        />
      </Section>
    </ScreenLayout>
  );
};

export interface ProductModifiersProps {
  productModifiers: ProductModifierGroup[];
  setProductModifiers: (value: ProductModifierGroup[]) => void;
  productId?: string;
  isModal?: boolean;
  isCombo?: boolean;
}

const getModifierGroupToDisplay = (
  modifierGroup: ProductModifierGroup,
  allModifierGroups: { [key: string]: ModifierGroup },
) => {
  if (modifierGroup.id === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) {
    return {
      id: modifierGroup.id,
      name: 'Miscellaneous',
    } as ModifierGroup;
  }
  if (allModifierGroups[modifierGroup.id])
    return allModifierGroups[modifierGroup.id];
  if (modifierGroup.name === DEFAULT_OPTION_GROUP) {
    const productAsModifiers = modifierGroup?.products;
    return {
      id: productAsModifiers?.[0].id,
      name: productAsModifiers?.[0].name,
    } as ModifierGroup;
  }
  return modifierGroup;
};

export const ProductModifiers: React.FC<ProductModifiersProps> = ({
  productModifiers,
  setProductModifiers,
  productId,
  isModal,
  isCombo,
}) => {
  const { translate } = useTranslation();
  const { currency } = useCurrency();
  const { showNotification } = useNotification();

  const { modifiers, getAllModifiers, error: modErr } = useModifiers();

  const {
    products,
    error: prodErr,
    getAllProducts,
  } = useProducts('', PRODUCT_MODIFIERS_FRAGMENT, undefined, 'no-cache');

  const {
    modifierGroups: allModifierGroups,
    getAllModifierGroups,
    error: modGroupErr,
  } = useModifierGroups();

  const filteredModifierGroups = useMemo(() => {
    const modifierGroups = Object.values(allModifierGroups || {});
    let filteredGroups: ModifierGroup[] = [];
    if (isCombo) {
      filteredGroups = modifierGroups.filter(group => group.products?.length);
    } else {
      filteredGroups = modifierGroups.filter(group => !group.products?.length);
    }
    return keyBy(filteredGroups, 'id');
  }, [isCombo, allModifierGroups]);

  const error = modErr || modGroupErr || prodErr;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    getAllModifiers();
    getAllModifierGroups();
    productId && getAllProducts();
  }, [getAllModifiers, getAllModifierGroups, getAllProducts, productId]);

  const isOnlyProductAsModifier = useMemo(() => {
    return (
      productModifiers?.length > 0 &&
      productModifiers.every(
        mod => mod?.products?.length && mod.modifiers.length === 0,
      )
    );
  }, [productModifiers]);

  const isOnlyModifier = useMemo(() => {
    return (
      productModifiers?.length > 0 &&
      productModifiers.every(
        mod =>
          mod.modifiers?.length > 0 &&
          (!mod?.products || mod.products.length === 0),
      )
    );
  }, [productModifiers]);

  const productValues = useMemo(() => {
    return isCombo
      ? []
      : Object.values(products).filter(
          prod => prod.id !== productId && !prod?.isCombo,
        );
  }, [products, productId, isCombo]);

  const modifiersAndModifierGroupsData = useMemo(() => {
    const modifierValues = isCombo ? [] : Object.values(modifiers);
    const modifiersOptions = modifierValues.map(x => ({
      value: x.id,
      title: x.name,
      type: ModifierType.Modifier,
      subtitle: translate('modifiers.modifier'),
    }));
    const modifierGroups = Object.values(filteredModifierGroups)
      .filter(modGroup => {
        if (isOnlyProductAsModifier)
          return modGroup.products && modGroup?.products?.length > 0;
        if (isOnlyModifier) return modGroup.modifiers?.length > 0;
        return modGroup;
      })
      .map(x => {
        const modifiersNames =
          x.modifiers
            ?.map((x: Modifier) => modifiers[x.id]?.name || '')
            .join(', ') ?? '';
        const productNames = x.products?.map(prod => prod.name).join(', ');
        const nameModified = `${x.name} (${
          modifiersNames.length ? modifiersNames : productNames
        })`;
        return {
          value: x.id,
          title: nameModified,
          type: ModifierType.ModifierGroup,
          subtitle: translate('modifiers.optionGroup'),
        };
      });
    const productsOptions = productValues.map(prod => ({
      value: prod.id,
      title: prod.name,
      type: ProductType.Product,
      subtitle: translate('modifiers.product'),
    }));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let modifierAndModifierGroups: any[] = [];

    if (isOnlyProductAsModifier) {
      modifierAndModifierGroups = [...productsOptions, ...modifierGroups];
    } else if (isOnlyModifier) {
      modifierAndModifierGroups = [...modifiersOptions, ...modifierGroups];
    } else {
      modifierAndModifierGroups = [
        ...modifiersOptions,
        ...modifierGroups,
        ...productsOptions,
      ];
    }
    const result = orderBy(modifierAndModifierGroups, ['label'], ['asc']);
    return result;
  }, [
    filteredModifierGroups,
    modifiers,
    translate,
    productValues,
    isOnlyModifier,
    isOnlyProductAsModifier,
    isCombo,
  ]);

  const dropDownOptionsDictionary = keyBy(
    modifiersAndModifierGroupsData,
    'value',
  );

  const keyByExistingModifiers = useCallback(() => {
    const existingProductModifiersMapTemp = keyBy(
      { ...productModifiers },
      'id',
    );
    delete existingProductModifiersMapTemp?.[
      DEFAULT_PRODUCT_MODIFIER_GROUP_NAME
    ];
    return existingProductModifiersMapTemp;
  }, [productModifiers]);

  const dropDownSelectedValues = useMemo(() => {
    const result: string[] = [];
    if (productModifiers.length) {
      productModifiers.forEach(x => {
        if (x.name === DEFAULT_OPTION_GROUP) {
          result.push(...x?.products?.map(pro => pro.id));
        } else if (x.id !== DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) {
          result.push(x.id);
        } else {
          result.push(...x.modifiers.map(x => x.id));
          result.push(...(x?.products || []).map(pro => pro.id));
        }
      });
    }
    return result;
  }, [productModifiers]);

  const onDeleteRow = useCallback(
    modifierGroupId => {
      const modifierGroupIndex = findIndex(productModifiers, {
        id: modifierGroupId,
      });
      const tempPrev = cloneDeep(productModifiers);
      if (modifierGroupIndex === -1) {
        tempPrev.forEach(prodMod => {
          prodMod.name === DEFAULT_OPTION_GROUP &&
            (prodMod.products = prodMod?.products.filter(
              pro => pro.id !== modifierGroupId,
            ));
        });
        const emptyProductsIndex = tempPrev.findIndex(
          modifierGroup => modifierGroup.products?.length === 0,
        );
        emptyProductsIndex !== -1 && tempPrev.splice(emptyProductsIndex, 1);
      } else {
        tempPrev.splice(modifierGroupIndex, 1);
      }
      setProductModifiers(tempPrev);
    },
    [productModifiers, setProductModifiers],
  );

  const onChange = useCallback(
    (modifierGroupId: string, prop, value, modifierId) => {
      const modifierGroupIndex = findIndex(productModifiers, {
        id: modifierGroupId,
      });
      if (modifierId) {
        const tempPrev = cloneDeep(productModifiers);
        const modifiersData = tempPrev[modifierGroupIndex].modifiers;
        const productAsModData = tempPrev[modifierGroupIndex].products;
        const modifierIndex = findIndex(modifiersData, { id: modifierId });
        const productIndex = findIndex(productAsModData, { id: modifierId });
        if (modifierIndex >= 0) {
          // If current changing item is a modifier
          modifiersData[modifierIndex] = {
            id: modifierId,
            isDefault: !modifiersData[modifierIndex].isDefault,
            price: modifiersData[modifierIndex]?.price,
          } as unknown as Modifier;
          tempPrev[modifierGroupIndex].modifiers = modifiersData;
          setProductModifiers(tempPrev);
        } else if (productIndex >= 0) {
          // If current changing item is a product but treat as modifier
          // this prop should be : 'isDefault' and value should be boolean
          productAsModData[productIndex] = {
            ...productAsModData[productIndex],
            [prop]: value,
          };
          tempPrev[modifierGroupIndex].products = productAsModData;
          setProductModifiers(tempPrev);
        }
      } else {
        const tempPrev = [...productModifiers];
        tempPrev[modifierGroupIndex] = {
          ...tempPrev[modifierGroupIndex],
          [prop]: value,
        };
        if (tempPrev[modifierGroupIndex]?.isRequired) {
          // when is Required true then is grouped must also be true

          tempPrev[modifierGroupIndex] = {
            ...tempPrev[modifierGroupIndex],
            isGrouped: true,
          };

          // if modifier is required then it should be moved to top automatically
          const cloneOfReqProdMod = { ...tempPrev[modifierGroupIndex] };
          tempPrev.splice(modifierGroupIndex, 1);
          tempPrev.splice(0, 0, cloneOfReqProdMod);
        }
        setProductModifiers(tempPrev);
      }
    },
    [productModifiers, setProductModifiers],
  );

  const mergeModifierGroups = useCallback(
    (
      existingModifierGroups: Dictionary<ProductModifierGroup>,
      newModifierGroupId: string,
      newProductModifiersMap: Dictionary<ProductModifierGroup>,
    ) => {
      const existingValue = { ...existingModifierGroups };
      const existingModifierGroup = existingValue?.[newModifierGroupId];
      const modifierGroupData = filteredModifierGroups[newModifierGroupId];
      const existingDetails: ProductModifierGroup = existingModifierGroup
        ? {
            ...existingModifierGroup,
            priority: existingModifierGroup.priority + 1,
          }
        : ({
            isGrouped: false,
            isRequired: false,
            modifiers: modifierGroupData.modifiers.map(
              tempMod =>
                ({
                  id: tempMod.id,
                  name: tempMod.name,
                  isDefault: false,
                  price: tempMod.price,
                } as Modifier),
            ),
            priority: 1,
          } as ProductModifierGroup);
      newProductModifiersMap[newModifierGroupId] = {
        ...modifierGroupData,
        ...existingDetails,
      } as ProductModifierGroup;
      return newProductModifiersMap;
    },
    [filteredModifierGroups],
  );

  const mergeModifiers = useCallback(
    (
      existingModifiersValues,
      newModifierId,
      newProductModifiersMap,
      currentMaxPriority,
    ) => {
      const existingModifiers = { ...existingModifiersValues };
      const defaultProdModData =
        existingModifiers?.[DEFAULT_PRODUCT_MODIFIER_GROUP_NAME];
      const existingDefaultModData =
        defaultProdModData ||
        ({
          isGrouped: false,
          isRequired: false,
          id: DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
          modifiers: [] as Modifier[],
          priority: currentMaxPriority,
        } as ProductModifierGroup);
      const isModExists = existingDefaultModData?.modifiers?.find(
        (x: Modifier) => x.id === newModifierId,
      );
      if (!isModExists) {
        existingDefaultModData.modifiers.push({
          id: newModifierId,
          isDefault: false,
        } as Modifier);
      }
      newProductModifiersMap[DEFAULT_PRODUCT_MODIFIER_GROUP_NAME] = {
        ...existingDefaultModData,
      } as ProductModifierGroup;
      return newProductModifiersMap;
    },
    [],
  );

  const mapProductAsModifierGroup = useCallback(
    (productIdSelected, newProductModifiersMap, currentMaxPriority) => {
      const product = productValues.find(prod => prod.id === productIdSelected);
      if (productId) {
        if (newProductModifiersMap[productId]) {
          newProductModifiersMap[productId].products.push({
            id: product?.id,
            name: product?.name,
            isDefault: false,
            price: {
              amount: 0,
              currency: currency,
            },
            priority: currentMaxPriority,
          });
        } else {
          const defaultModifierGroup = {
            isGrouped: false,
            isRequired: false,
            id: productId,
            name: DEFAULT_OPTION_GROUP,
            modifiers: [] as Modifier[],
            products: [
              {
                id: product?.id,
                name: product?.name,
                isDefault: false,
                price: {
                  amount: 0,
                  currency: currency,
                },
                priority: currentMaxPriority,
              },
            ],
            priority: 1,
          } as ProductModifierGroup;

          newProductModifiersMap[productId] = {
            ...defaultModifierGroup,
          } as ProductModifierGroup;
        }
      }

      return newProductModifiersMap;
    },
    [productValues, currency, productId],
  );

  // Get the maximum priority in the product modifier group
  const getMaxPriorityFromProductModifiersData = useCallback(
    (existingProductModifiersMap: Dictionary<ProductModifierGroup>) => {
      const maxPriority = Object.values(existingProductModifiersMap).reduce(
        (maxPriority, item) => {
          if (item.name === DEFAULT_OPTION_GROUP) {
            item.products.forEach(prod => {
              if (prod.priority && prod.priority > maxPriority)
                maxPriority = prod.priority;
            });
          } else {
            maxPriority =
              maxPriority > item.priority ? maxPriority : item.priority;
          }
          return maxPriority;
        },
        0,
      );
      return maxPriority;
    },
    [],
  );

  // Get priority map for each mod group data
  const getPriorityMapFromProductModifiersData = useCallback(
    (existingProductModifiersMap: Dictionary<ProductModifierGroup>) => {
      const currPriorityMap: { [key: string]: number } = {};
      Object.values(existingProductModifiersMap).forEach(item => {
        if (item.name === DEFAULT_OPTION_GROUP) {
          item.products.forEach(prod => {
            currPriorityMap[prod.id] = prod?.priority || 0;
          });
        } else {
          currPriorityMap[item.id] = item.priority;
        }
      });
      return currPriorityMap;
    },
    [],
  );

  const onChangeOfDropDown = useCallback(
    (values: string[]) => {
      let existingProductModifiersMap = keyByExistingModifiers();
      const currPriorityMap: { [key: string]: number } =
        getPriorityMapFromProductModifiersData(existingProductModifiersMap);

      const maxPriorityInDataOld = getMaxPriorityFromProductModifiersData(
        existingProductModifiersMap,
      );
      // calculate the biggest priority number when changing the selection
      const biggestPriority =
        values.length > maxPriorityInDataOld
          ? values.length
          : maxPriorityInDataOld + 1;

      let newProductModifiersMap: Dictionary<ProductModifierGroup> = {};
      values.forEach(eachVal => {
        const isProduct =
          dropDownOptionsDictionary[eachVal]?.type === ProductType.Product;
        const isModifiersGroup =
          dropDownOptionsDictionary[eachVal]?.type ===
          ModifierType.ModifierGroup;
        // Save the correct priority number for each item in values
        const savedOrderNum = currPriorityMap[eachVal]
          ? currPriorityMap[eachVal]
          : biggestPriority;
        if (isModifiersGroup) {
          newProductModifiersMap = mergeModifierGroups(
            existingProductModifiersMap,
            eachVal,
            newProductModifiersMap,
          );
        } else if (isProduct) {
          newProductModifiersMap = mapProductAsModifierGroup(
            eachVal,
            newProductModifiersMap,
            savedOrderNum,
          );
        } else {
          existingProductModifiersMap = mergeModifiers(
            existingProductModifiersMap,
            eachVal,
            newProductModifiersMap,
            savedOrderNum,
          );
        }
      });
      const existingModifiersArray = Object.values(newProductModifiersMap);

      setProductModifiers(existingModifiersArray);
    },
    [
      keyByExistingModifiers,
      setProductModifiers,
      dropDownOptionsDictionary,
      mergeModifierGroups,
      mergeModifiers,
      mapProductAsModifierGroup,
      getPriorityMapFromProductModifiersData,
      getMaxPriorityFromProductModifiersData,
    ],
  );

  const onDragEnd = useCallback(
    (data: Array<ProductModifierGroup>) => {
      // should not able to drag non required modifier on top of required modifiers
      // or always required modifiers should be on top
      let canChange = true;
      const indexesOfRequiredModifiers = data
        .map((x, index) => x && x.isRequired && index)
        .filter(index => !!index);
      data.forEach((eachProdModifier, index) => {
        if (!eachProdModifier.isRequired) {
          indexesOfRequiredModifiers.forEach(reqPriority => {
            if ((reqPriority || 0) >= index) {
              // do not allow to change
              canChange = false;
              showNotification({
                error: true,
                message: translate('modifiers.dragLimitationError'),
              });
            }
          });
        }
      });

      canChange &&
        setProductModifiers(
          data.map((x, index) => {
            x.name === DEFAULT_OPTION_GROUP &&
              x.products.forEach(pro => (pro.priority = index));
            return { ...x, priority: index };
          }),
        );
    },
    [showNotification, translate, setProductModifiers],
  );

  const productModifierRowData = useMemo(() => {
    const data = [] as unknown as ProductModifierGroup[];
    productModifiers.forEach(proModGroup => {
      if (proModGroup.name === DEFAULT_OPTION_GROUP) {
        data.push(
          ...proModGroup?.products?.map(
            prod =>
              ({
                isGrouped: false,
                isRequired: false,
                id: prod.id,
                name: DEFAULT_OPTION_GROUP,
                modifiers: [] as Modifier[],
                products: [
                  {
                    id: prod?.id,
                    name: prod?.name,
                    isDefault: false,
                    price: {
                      amount: 0,
                      currency: currency,
                    },
                    priority: prod.priority,
                  },
                ],
                priority: prod.priority,
              } as ProductModifierGroup),
          ),
        );
      } else data.push(proModGroup);
    });
    return orderBy(data, ['priority'], ['asc']);
  }, [currency, productModifiers]);

  return (
    <View>
      <SelectSearch
        options={modifiersAndModifierGroupsData}
        selectedOptions={dropDownSelectedValues}
        onChangeOption={e => onChangeOfDropDown(e)}
        placeholder={translate('modifiers.searchModifiersAndGroupsByName')}
      />
      <View style={styles.tableContainer}>
        <View style={theme.tables.header}>
          <Text style={[theme.tables.headerText, styles.headerGroup]}>
            {translate(
              isCombo ? 'modifiers.optionGroup' : 'modifiers.modifiers',
            )}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerToggleGroup]}>
            {translate('modifiers.group')}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerToggleRequire]}>
            {translate('modifiers.required')}
          </Text>
        </View>
        <View>
          {productModifiers.length ? (
            <DraggableFlatList
              columns={[]}
              normalRows
              onDragEnd={(data: Array<ProductModifierGroup>) => onDragEnd(data)}
              data={productModifierRowData}
              renderRow={(
                modifierGroup: ProductModifierGroup,
                index: number,
                drag: () => void,
              ): React.ReactNode => (
                <ProductModifierRow
                  isGrouped={modifierGroup.isGrouped}
                  isRequired={modifierGroup.isRequired}
                  modifiers={modifierGroup.modifiers.map(x => ({
                    ...modifiers[x.id],
                    isDefault: x.isDefault,
                    price: x.price,
                    priority: x?.priority || index,
                  }))}
                  isProductAsModifierGroup={
                    modifierGroup.name === DEFAULT_OPTION_GROUP
                  }
                  products={modifierGroup.products}
                  modifierGroup={getModifierGroupToDisplay(
                    modifierGroup,
                    filteredModifierGroups,
                  )}
                  onChange={onChange}
                  onDeleteRow={onDeleteRow}
                  rowIndex={index}
                  key={index}
                  drag={drag}
                  isCombo={isCombo}
                />
              )}
              containerStyle={isModal && styles.tableBody}
            />
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {translate('modifiers.emptyBoxMsg')}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
