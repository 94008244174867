import React, { useCallback } from 'react';
import { View, Text, Linking, Platform } from 'react-native';
import { IntegrationApps } from '@oolio-group/domain';
import theme from '../../../../../../common/default-theme';
import styles from './Settings.styles';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface StoreRowProps {
  isEnabled: boolean;
  isConnected: boolean;
  name: string;
  integrationApp: IntegrationApps;
}

export const StoreRow: React.FC<StoreRowProps> = ({
  isEnabled,
  isConnected,
  name,
}: StoreRowProps) => {
  const navigateToReservationsPortal = useCallback(() => {
    const oolioReservationsPortalUrl = process.env
      .REACT_APP_OOLIO_RESERVATIONS_URL as string;

    if (Platform.OS === 'web') {
      window.open(oolioReservationsPortalUrl, '_blank');
    } else {
      Linking.openURL(oolioReservationsPortalUrl);
    }
  }, []);

  return (
    <View style={theme.tables.row}>
      <InputToggle
        testID="toggle-store"
        type="switch"
        isToggled={isEnabled && isConnected}
        disabled={true}
      />
      <Text style={styles.cellName}>{name}</Text>
      {
        <ButtonIcon
          type="neutralLight"
          icon="cog"
          onPress={navigateToReservationsPortal}
          containerStyle={styles.cellAccessory}
        />
      }
    </View>
  );
};
