import React, { useCallback, useMemo, useState } from 'react';
import {
  View,
  Text,
  ViewStyle,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native';
import { useTranslation } from '@oolio-group/localization/dist';
import Search from '../Search/Search';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';

export type OptionsType = {
  type?: string;
  value: string;
  title: string;
  subtitle?: string;
  selected?: boolean;
};

interface SelectSearchProps {
  title?: string;
  testID?: string;
  placeholder?: string;
  options: OptionsType[];
  selectedOptions?: string[];
  containerStyle?: ViewStyle;
  disabled?: boolean;
  onSearchChange?: (search: string) => void;
  onChangeOption?: (selectedValues: string[], item: OptionsType) => void;
  loading?: boolean;
}

const SelectSearch: React.FC<SelectSearchProps> = ({
  title,
  testID,
  placeholder,
  options,
  selectedOptions,
  containerStyle,
  disabled,
  onChangeOption,
  onSearchChange,
  loading,
}) => {
  const { translate } = useTranslation();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const onSearchTextChange = (value: string): void => {
    setSearchString(value);
    onSearchChange && onSearchChange(value);
  };

  const searchResults = useMemo(() => {
    if (searchString !== '' && options && options.length) {
      const searchLabel = options.filter((eachValue: OptionsType) =>
        eachValue.title?.toLowerCase().includes(searchString.toLowerCase()),
      );
      setShowOptions(true);
      return searchLabel;
    } else {
      setShowOptions(false);
      return options;
    }
  }, [searchString, options]);

  const onChange = useCallback(
    (selected, item: OptionsType) => {
      const id = item.value;
      if (selectedOptions) {
        const index = selectedOptions.indexOf(id);
        if (selected && index <= -1) {
          selectedOptions.push(id);
        } else {
          selectedOptions.splice(index, 1);
        }
      }
      onChangeOption && onChangeOption(selectedOptions || [id], item);
      setShowOptions(false);
      setSearchString('');
    },
    [selectedOptions, onChangeOption],
  );

  return (
    <KeyboardAvoidingView behavior="padding" style={containerStyle}>
      <Search
        testID={testID || 'search'}
        title={title}
        editable={!disabled}
        value={searchString}
        placeholder={placeholder}
        onChangeText={e => onSearchTextChange(e)}
        loading={loading}
      />
      {showOptions && (
        // eslint-disable-next-line react-native/no-inline-styles
        <View style={[localStyles.options, { top: !!title ? 72 : 50 }]}>
          <ScrollView>
            {searchResults.length > 0 ? (
              searchResults.map((item, i: number) => {
                const selected =
                  (selectedOptions &&
                    selectedOptions.indexOf(item.value) !== -1) ||
                  false;
                return (
                  <TouchableOpacity
                    key={i}
                    testID="placeItem"
                    style={localStyles.option}
                    onPress={() => onChange(!selected, item)}
                  >
                    <View style={localStyles.text}>
                      {item.subtitle ? (
                        <Text style={localStyles.subtitle}>
                          {item.subtitle}
                        </Text>
                      ) : null}
                      <Text numberOfLines={1}>{item.title}</Text>
                    </View>
                    <View style={localStyles.icon}>
                      <Icon
                        name={selected ? 'times' : 'plus'}
                        size={20}
                        color={
                          selected
                            ? theme.colors.states.negative
                            : theme.colors.states.positive
                        }
                      />
                    </View>
                  </TouchableOpacity>
                );
              })
            ) : (
              <Text style={localStyles.noResultsText}>
                {translate('placesInput.noResults')}
              </Text>
            )}
          </ScrollView>
        </View>
      )}
    </KeyboardAvoidingView>
  );
};

export default SelectSearch;

const localStyles = StyleSheet.create({
  options: {
    width: '100%',
    maxHeight: 240,
    position: 'absolute',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.light,
    ...theme.shadow.light,
    borderWidth: 1,
    borderColor: theme.colors.grey3,
  },
  option: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: theme.colors.grey3,
  },
  subtitle: {
    fontSize: 12,
    color: theme.colors.grey6,
    marginBottom: 6,
  },
  text: {
    flex: 1,
  },
  icon: {
    width: 20,
  },
  noResultsText: {
    lineHeight: 60,
    textAlign: 'center',
    color: theme.colors.grey5,
  },
});
