/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState } from 'react';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import {
  Money,
  Currency,
  DEFAULT_TAX,
  CreateModifierInput,
  ModifierType,
} from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import Select from '../../../../../components/Shared/Select/Select';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';

interface CreateModifierInputAlias extends CreateModifierInput {
  tax?: string;
  price?: Money;
}

interface CreateModifierModalProps {
  onCreate: (prodInput: CreateModifierInputAlias) => void;
  taxesOptions: { label: string; value: string }[];
  modifierGroups: { label: string; value: string }[];
  modifierDefaultValues?: Partial<CreateModifierInputAlias>;
}

export const CreateModifierModal: React.FC<CreateModifierModalProps> = ({
  onCreate,
  taxesOptions,
  modifierGroups,
  modifierDefaultValues,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { currency, unAppendCurrency, currencySymbol } = useCurrency();
  // default tax should be GST
  const defaultTax =
    taxesOptions?.find(x => x.label === DEFAULT_TAX)?.value ||
    taxesOptions?.[0]?.value;
  const [modifiersForm, setModifiersForm] = useState({
    tax: defaultTax,
    type: ModifierType.PRODUCT, // default type
    name: modifierDefaultValues?.name || '',
    modifierGroups: modifierDefaultValues?.modifierGroups || [],
    price: {
      amount: 0,
      currency: currency,
    },
  } as CreateModifierInputAlias);
  const { showNotification } = useNotification();

  const onChange = useCallback(
    (prop: string, value) => {
      if (prop === 'price') {
        const temp = unAppendCurrency(value);
        value = { currency: currency as Currency, amount: temp };
      }
      setModifiersForm(prev => {
        return { ...prev, [prop]: value };
      });
    },
    [unAppendCurrency, currency],
  );

  const onCreateModifier = useCallback(() => {
    let message = '';
    if (!modifiersForm.name) {
      message = translate('modifiers.pleaseEnterAModifierName');
    } else if (!modifiersForm?.tax) {
      message = translate('modifiers.pleaseSelectSellingTaxForTheModifier');
    } else {
      let createProdForm = { ...modifiersForm };
      const price = modifiersForm?.price?.amount || 0;
      createProdForm = {
        ...modifiersForm,
        name: modifiersForm.name.trim(),
        price: {
          amount: +price,
          currency: currency as Currency,
        },
      };
      onCreate(createProdForm);
    }
    if (message) {
      showNotification({
        error: true,
        message,
      });
    }
  }, [onCreate, modifiersForm, showNotification, translate, currency]);

  const sellingPrice = modifiersForm?.price?.amount;

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <TreatModal
        type="positive"
        title={translate('modifiers.createNewModifier')}
        onConfirm={{ action: onCreateModifier }}
        onDismiss={{ action: closeModal }}
      >
        <InputText
          testID="input-name"
          title={translate('modifiers.modifierName')}
          value={modifiersForm.name}
          placeholder={translate('modifiers.modifierName')}
          onChangeText={onChange.bind(null, 'name')}
          containerStyle={{ marginBottom: 20 }}
        />
        <SelectMultiple
          testID="select-groups"
          title={translate('modifiers.optionGroups')}
          options={modifierGroups}
          selectedValues={modifiersForm.modifierGroups}
          onValueChange={onChange.bind(null, 'modifierGroups')}
          containerStyle={{ marginBottom: 20 }}
        />
        <InputText
          testID="input-price"
          alignText="right"
          label={currencySymbol}
          title={translate('backOfficeProducts.productPrice')}
          placeholder={translate('backOfficeProducts.productPrice')}
          value={sellingPrice?.toString()}
          onChangeText={onChange.bind(null, 'price')}
          containerStyle={{ marginBottom: 20 }}
        />
        <Select
          testID="select-tax"
          title={translate('modifiers.taxRate')}
          options={taxesOptions}
          selectedValue={modifiersForm.tax}
          onValueChange={onChange.bind(null, 'tax')}
        />
      </TreatModal>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, alignContent: 'center', justifyContent: 'center' },
});
