import { useReactiveVar } from '@apollo/client';
import {
  optimisticReservationsMap,
  reservationsToday,
} from '../../../state/cache';
import { useMemo } from 'react';
import { Reservation } from '@oolio-group/domain';
import { mergeReservationsWithOptimisticUpdates } from '../../../screens/POS/Reservations/optimisticReservationsUtils';

export const useTodayReservationsWithOptimisticUpdates = (): Reservation[] => {
  const todayReservations = useReactiveVar(reservationsToday);
  const optimisticUpdates = useReactiveVar(optimisticReservationsMap);
  return useMemo(
    () =>
      mergeReservationsWithOptimisticUpdates(
        todayReservations,
        optimisticUpdates,
      ),
    [todayReservations, optimisticUpdates],
  );
};
