import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  reservationsToday,
  optimisticReservationsMap,
} from '../../../state/cache';
import { useSession } from '../../../hooks/app/useSession';
import { useApolloClient } from '@apollo/client/react/hooks';
import { GET_INTEGRATION_PARTNERS } from '../../../hooks/app/useIntegrationPartners/graphql';
import { IntegrationApps } from '@oolio-group/domain';
import { useReservations } from '../../../hooks/app/reservations/useReservations';
import { format } from 'date-fns';
import { clearStaleOptimisticReservations } from './optimisticReservationsUtils';

const RESERVATIONS_POLLING_INTERVAL = 10000; // 10 seconds;

export const ReservationHermes: React.FC = () => {
  const client = useApolloClient();
  const { fetchReservationsData } = useReservations();
  const [session] = useSession();
  const [posLocationId, setPosLocationId] = useState<string>();
  const intervalRef = useRef<number | null>(null);

  const fetchPosLocationId = useCallback(async () => {
    if (!session?.currentStore?.id) return;

    try {
      const { data } = await client.query({
        query: GET_INTEGRATION_PARTNERS,
        variables: {
          filter: {
            appName: IntegrationApps.OOLIO_RESERVATION,
            store: session.currentStore.id,
          },
        },
        fetchPolicy: 'network-only',
      });

      const integrationPartner = data?.integrationPartners?.[0];
      const posLocationId =
        integrationPartner?.preferences?.oolioReservation?.posLocationId;

      if (!posLocationId) {
        console.log('posLocationId not found');
        return;
      }
      setPosLocationId(posLocationId);
    } catch (err) {
      console.log('error fetching posLocationId:', err);
    }
  }, [client, session?.currentStore?.id]);

  useEffect(() => {
    fetchPosLocationId();
  }, [fetchPosLocationId]);

  const pollReservations = useCallback(async () => {
    if (!session?.currentOrganization?.id || !posLocationId) return;
    const today = format(new Date(), 'yyyy-MM-dd');

    try {
      const data = await fetchReservationsData({
        posLocationId,
        organizationId: session.currentOrganization.id,
        fromDate: today,
        toDate: today,
      });
      reservationsToday(data || []);
    } catch (err) {
      console.log('error fetching reservations:', err);
    }
  }, [posLocationId, session?.currentOrganization?.id, fetchReservationsData]);

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        pollReservations();
        if (Object.keys(optimisticReservationsMap()).length > 0) {
          clearStaleOptimisticReservations();
        }
      }, RESERVATIONS_POLLING_INTERVAL);

      pollReservations(); // init run
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [pollReservations]);

  return null;
};
