import React, { useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { Variant, UpdateVariantInput } from '@oolio-group/domain';
import { ProductModel } from '@oolio-group/dal';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { VARIANT_AVAILABILITY_FRAGMENT } from '../../../../../hooks/app/variants/graphql';
import theme from '../../../../../common/default-theme';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import {
  CUSTOM_CHANNELS_STORES_OPTIONS_QUERY,
  useAvailabilityOptions,
} from '../../../../../hooks/app/useAvailabilityOptions';

interface AvailabilityVariantProps {
  variantId: string;
}

export const AvailabilityVariant: React.FC<AvailabilityVariantProps> = ({
  variantId,
}) => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [variant, setVariant] = useState({} as ProductModel);

  const {
    error: varErr,
    variants,
    updateVariant,
    loading: varLoading,
  } = useVariants(variantId, VARIANT_AVAILABILITY_FRAGMENT);

  const error = varErr;

  const {
    salesChannels,
    stores,
    loading: optionsLoading,
  } = useAvailabilityOptions(CUSTOM_CHANNELS_STORES_OPTIONS_QUERY);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (variantId && variants && variants[variantId]) {
      const variantData = variants[variantId] as Variant;
      const productModal = {
        ...variantData,
        salesChannels: variantData.salesChannels.map(x => x.id),
        stores: variantData.stores.map(x => x.id),
      } as unknown as ProductModel;
      setVariant(productModal);
    }
  }, [variantId, variants]);

  const onChange = useCallback((prop: string, value): void => {
    setVariant(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onSaveProduct = useCallback((): void => {
    const updateProductInput = {
      id: variant.id,
      name: variant.name,
      salesChannels: variant.salesChannels,
      stores: variant.stores,
    } as UpdateVariantInput;

    updateVariant(updateProductInput);
  }, [variant, updateVariant]);

  const loading = optionsLoading || varLoading;

  if (loading) return <LoadingIndicator />;

  return (
    <ScreenLayout title="Product Availability | Oolio" onSave={onSaveProduct}>
      <Section
        title={translate('productSettings.availability')}
        subtitle={translate('productSettings.availabilityDescription')}
      >
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-channels"
            title={translate('productSettings.channels')}
            options={salesChannels || []}
            selectedValues={variant.salesChannels || []}
            containerStyle={theme.forms.inputFluid}
            onValueChange={value => onChange('salesChannels', value)}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-channels"
            title={translate('backOfficeUsers.stores')}
            options={stores || []}
            selectedValues={variant.stores || []}
            containerStyle={theme.forms.inputFluid}
            onValueChange={value => onChange('stores', value)}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
