import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { endOfDay, startOfDay } from 'date-fns';
import {
  Booking,
  BookingStatusEnum,
  CreateBookingInput,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useBookings } from '../../../hooks/app/bookings/useBookings';
import styles from './Bookings.styles';
import BookingsTable from './Table/BookingsTable';
import CreateBookingModal from './Modal/CreateBookingModal';
import BookingSidePanel from './SidePanel/BookingSidePanel';
import Search from '../../../components/Shared/Search/Search';
import { Option } from '../../../components/Shared/Select/Select';
import TreatPicker from '../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import CreateButton from '../../../components/Office/CreateButton/CreateButton';

const STATUS_OPTIONS: Option[] = [
  { label: 'All Statuses', value: 'ALL' },
  { label: 'Waitlist', value: 'WAITLIST' },
];

const WAITLIST_STATUSES = [
  BookingStatusEnum.CREATED,
  BookingStatusEnum.READY,
  BookingStatusEnum.CHECKED_IN,
];

const BookingsScreen: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState('WAITLIST');

  const { getBookings, bookingMaps, createBooking, loading, updateBooking } =
    useBookings({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    });

  const getBookingsData = useCallback(() => {
    getBookings({
      startDate: startOfDay(new Date()).getTime(),
      endDate: endOfDay(new Date()).getTime(),
    });
    setCurrentPage(1);
  }, [getBookings]);

  const filterByStatus = useCallback(
    bookingStatus => {
      if (selectedStatus === 'ALL') return true;
      if (selectedStatus === 'WAITLIST')
        return WAITLIST_STATUSES.includes(bookingStatus);
      return bookingStatus === selectedStatus;
    },
    [selectedStatus],
  );

  const bookings = useMemo(() => Object.values(bookingMaps), [bookingMaps]);

  const filteredList = useMemo(() => {
    return bookings
      .sort((a, b) => Number(b.priority) - Number(a.priority))
      .filter(entry => {
        let valid = filterByStatus(entry.status);

        const name = `${entry.customer.firstName} ${entry.customer.lastName} ${entry.customer.phoneNumber} ${entry.customer.email}`;
        if (valid && searchText) {
          valid = name.toLowerCase().includes(searchText.toLowerCase());
        }
        return valid;
      });
  }, [bookings, filterByStatus, searchText]);

  const onCreateBooking = useCallback(
    (input: CreateBookingInput) => {
      createBooking(input);
      closeModal();
    },
    [closeModal, createBooking],
  );

  const handleOnAddEntry = useCallback(() => {
    showModal(
      <CreateBookingModal onDismiss={closeModal} onConfirm={onCreateBooking} />,
    );
  }, [closeModal, onCreateBooking, showModal]);

  const onUpdateStatus = useCallback(
    (id: string, status: BookingStatusEnum) => {
      updateBooking({ id, status });
    },
    [updateBooking],
  );

  const onPressRefresh = useCallback(() => {
    getBookingsData();
  }, [getBookingsData]);

  const onSelectBooking = useCallback((entry: Booking) => {
    setSelectedBookingId(entry.id);
    setShowSidePanel(true);
  }, []);

  const handleHideSidePanel = useCallback(() => {
    setSelectedBookingId('');
    setShowSidePanel(false);
  }, []);

  const selectedBooking = useMemo(() => {
    return bookingMaps[selectedBookingId];
  }, [bookingMaps, selectedBookingId]);

  useEffect(() => {
    getBookingsData();
  }, [getBookingsData]);

  return (
    <ScreenLayout
      loading={loading}
      title={translate('bookings.bookings')}
      onBack={() => navigation.goBack()}
    >
      <View style={styles.container}>
        <View style={styles.filters}>
          <TreatPicker
            testID="select-status"
            options={STATUS_OPTIONS}
            selectedValue={selectedStatus}
            onValueChange={setSelectedStatus}
            containerStyle={styles.dropdown}
          />
          <Search
            testID="search-bookings"
            onChangeText={setSearchText}
            placeholder={translate('bookings.searchBookings')}
            containerStyle={styles.search}
          />
          <ButtonIcon
            testID="btn-refresh"
            size={44}
            icon="sync"
            type="neutral"
            onPress={onPressRefresh}
          />
          <CreateButton onPress={handleOnAddEntry} />
        </View>
        <BookingsTable
          bookings={filteredList}
          onSelectBooking={onSelectBooking}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          status={selectedStatus}
        />
        <BookingSidePanel
          show={showSidePanel}
          booking={selectedBooking}
          onDismiss={handleHideSidePanel}
          onUpdate={onUpdateStatus}
        />
      </View>
    </ScreenLayout>
  );
};

export default BookingsScreen;
