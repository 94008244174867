import pickBy from 'lodash/pickBy';
import {
  optimisticReservationsMap,
  reservationsToday,
} from '../../../state/cache';
import { Reservation } from '@oolio-group/domain';

const STALE_OPTIMISTIC_UPDATE_THRESHOLD = 30000; // 30 seconds;

export const addOptimisticReservation = (
  referenceCode: string,
  updateFields: Partial<Reservation>,
) => {
  const existingUpdates = optimisticReservationsMap();
  const newUpdates = {
    ...existingUpdates,
    [referenceCode]: {
      reservation: updateFields,
      addedAt: Date.now(),
    },
  };
  optimisticReservationsMap(newUpdates);
};

export const clearAllOptimisticReservations = () =>
  optimisticReservationsMap({});

export const clearStaleOptimisticReservations = () => {
  const now = Date.now();
  const todayReservations = reservationsToday();
  const optimisticUpdates = optimisticReservationsMap();

  const filteredUpdates = pickBy(optimisticUpdates, ({ addedAt }, refCode) => {
    const serverReservation = todayReservations.find(
      r => r.reference_code === refCode,
    );
    // Remove if no matching server reservation exists
    if (!serverReservation) return false;

    // Remove if the update is older than the threshold
    if (now - addedAt >= STALE_OPTIMISTIC_UPDATE_THRESHOLD) return false;

    // Remove if the server reservation has more recent data
    if (new Date(serverReservation.updated).getTime() >= addedAt) return false;

    // Otherwise, keep the optimistic update
    return true;
  });
  optimisticReservationsMap(filteredUpdates);
};

export const getTodayReservationsWithOptimisticUpdates = (): Reservation[] => {
  return mergeReservationsWithOptimisticUpdates(
    reservationsToday(),
    optimisticReservationsMap(),
  );
};

export const mergeReservationsWithOptimisticUpdates = (
  reservations: Reservation[],
  updates: Record<
    string,
    { reservation: Partial<Reservation>; addedAt: number }
  >,
): Reservation[] => {
  return reservations.map(reservation => {
    const optimisticUpdate = updates[reservation.reference_code];

    const isValidOptimisticUpdate =
      (optimisticUpdate?.addedAt || 0) >
      new Date(reservation.updated).getTime();

    return isValidOptimisticUpdate
      ? { ...reservation, ...optimisticUpdate.reservation }
      : reservation;
  });
};
