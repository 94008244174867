import { gql } from '@apollo/client';

const defaultProductGraphQuery = `
  id
  name
  maxSellingPrice
  minSellingPrice
  measuredBy {
    unitType
    defaultSize
    units
  }
  variablePricing
  variableQuantity
  isAvailable
  storesInventory
  imageUrl
  isCombo
  comboType
  modifierGroups{
    id
    name
    isGrouped
    isRequired,
    priority
    modifiers {
      id
      isDefault
      price {
        amount
        currency
      }
      priority
    }
    selectionLimit {
      min
      max
    }
  }
  productType {
    id
    name
  }
  printerProfiles{
    id
    name
    printerProfileType
  }
  barcode
  sku
  plu
  gtin
  isSellable
  isFeatured
  color
  optionValues {
    parentOptionId
    key
    value
  }
  measuredBy {
    unitType
    defaultSize
    units
  }
  description
  dietaryTags {
    id
    name
  }
  allergens
  recipe
  nutrientInfo {
    name
    value
    unit
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  salesChannels {
    id
  }
  stores {
    id
  }
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
      costTax {
        id
      }
      costPrice {
        amount
        currency
      }
    }
  }
`;

export const DELETE_PRODUCTS_MUTATION = gql`
  mutation deleteProducts($input: [ID!]!) {
    deleteProducts(ids: $input)
  }
`;

export const UPLOAD_PRODUCT_IMAGE_MUTATION = gql`
  mutation uploadProductImage($input: ImageUploadInput, $productId: String) {
    uploadProductImage(input: $input, productId: $productId)
  }
`;

/**
 *custom fragments of product screen and product settings
 */

export const MODIFIERS_GROUP_FRAGMENT = `
  modifierGroups{
    id
    name
    isGrouped
    isRequired,
    priority
    modifiers {
      id
      isDefault
      price {
        amount
        currency
      }
      priority
    }
    products {
      id
      isDefault
      name
      price {
        amount
        currency
      }
      priority
    }
  }
`;

export const PRODUCT_MODIFIERS_FRAGMENT = `
  id
  name
  isCombo
  comboType
  ${MODIFIERS_GROUP_FRAGMENT}
`;

export const PRODUCTS_IN_OPTIONS_GROUP_SCREEN_FRAGMENT = `
  id
  name
  productType {
    id
    name
  }
  imageUrl
  isSellable
  stores {
    id
  }
  isCombo
  comboType
  ${MODIFIERS_GROUP_FRAGMENT}
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      costPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
  category {
    id
    name
  }
  barcode
`;

export const PRODUCTS_IN_PRODUCT_LIST_SCREEN = `
  id
  name
  isSellable
  isCombo
  comboType
  printerProfiles{
    id
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  barcode
`;

export const PRODUCT_DETAIL_IN_PRODUCT_LIST_SCREEN = `
  id
  name
  isSellable
  isCombo
  comboType
  printerProfiles{
    id
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  barcode
  category {
    id
  }
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
  productType {
    id
  }
`;

export const PRODUCTS_IN_VARIANTS_SCREEN = `
  id
  name
  isSellable
  isCombo
  comboType
  printerProfiles{
    id
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  barcode
    pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
`;

export const CREATE_AND_UPDATE_PRODUCT_PRICINGS_IN_PRODUCT_LIST_SCREEN = `
  id
  name
  isSellable
  isCombo
  comboType
  printerProfiles{
    id
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  barcode
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
`;

export const PRODUCT_GENERAL_FRAGMENT = `
  id
  name
  isSellable
  isFeatured
  variableQuantity
  imageUrl
  productType {
    id
    name
  }
  printerProfiles{
    id
    name
    printerProfileType
  }
  barcode
  sku
  plu
  gtin
  color
  dietaryTags {
    id
    name
  }
  allergens
  nutrientInfo {
    name
    value
    unit
  }
  recipe
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  measuredBy {
    unitType
    defaultSize
    units
  }
  description
  isCombo
  comboType
  category {
    id
    name
  }
`;

export const PRODUCT_AVAILABILITY_FRAGMENT = `
  id
  name
  salesChannels {
    id
  }
  stores {
    id
  }
`;

export const PRODUCT_PRICING_FRAGMENT = `
  id
  name
  variablePricing
  maxSellingPrice
  minSellingPrice
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
      costTax {
        id
      }
      costPrice {
        amount
        currency
      }
    }
  }
`;

export const inventoryProductFragment = `
  id
  storesInventory
`;

export const ProductNameFragment = `
  id
  name
`;

export const getProductFragments = (customFragment?: string) => {
  const graphqlFragment = customFragment || defaultProductGraphQuery;
  const GET_PRODUCTS_QUERY = gql`
    query products {
      products {
        ${graphqlFragment}
      }
    }
  `;

  const GET_PRODUCT_QUERY = gql`
    query productPricesInfo($id: String!) {
      product(id: $id) {
        ${graphqlFragment}
      }
    }
  `;

  const UPDATE_PRODUCT_MUTATION = gql`
    mutation updateProduct($input: UpdateProductInput) {
      updateProduct(input: $input) {
        ${graphqlFragment}
      }
    }
  `;
  const UPDATE_PRODUCTS_MUTATION = gql`
    mutation updateProducts($input: [UpdateProductInput]) {
      updateProducts(input: $input) {
        ${graphqlFragment}
      }
    }
  `;
  const GET_PRODUCTS_WITH_FILTER = gql`
   query products($input: String) {
     products(filter:{name: $input}){
       ${graphqlFragment}
     }
   }
 `;

  const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct($input: CreateProductInput) {
    createProduct(input: $input) {
      ${CREATE_AND_UPDATE_PRODUCT_PRICINGS_IN_PRODUCT_LIST_SCREEN}
    }
  }
`;

  const COPY_PRODUCT_MUTATION = gql`
  mutation copyProduct($input: CopyProductInput) {
    copyProduct(input: $input) {
      ${graphqlFragment}
    }
  }
`;
  const UPDATE_PRODUCT_AVAILABILITY = gql`
  mutation updateProductsAvailability($input: [UpdateProductAvailabilityInput]){
    updateProductsAvailability(input: $input){
      ${graphqlFragment}
    }
  }`;

  const PRODUCT_CACHE_FRAGMENT = gql`
  fragment IProduct on Product {
    ${graphqlFragment}
  }`;

  const PRODUCT_IDS_OF_STORES = gql`
    query products($filter: ProductFilterInput) {
      products(filter: $filter) {
        id
      }
    }
  `;

  const PRODUCT_IDS_OF_MOD_GROUPS = gql`
    query products($filter: ProductFilterInput) {
      products(filter: $filter) {
        id
      }
    }
  `;

  return {
    GET_PRODUCTS_QUERY,
    GET_PRODUCT_QUERY,
    UPDATE_PRODUCT_MUTATION,
    UPDATE_PRODUCTS_MUTATION,
    GET_PRODUCTS_WITH_FILTER,
    CREATE_PRODUCT_MUTATION,
    COPY_PRODUCT_MUTATION,
    UPDATE_PRODUCT_AVAILABILITY,
    PRODUCT_CACHE_FRAGMENT,
    PRODUCT_IDS_OF_STORES,
    PRODUCT_IDS_OF_MOD_GROUPS,
  };
};

export const UPDATE_PRODUCTS_AVAILABILITY_EVENT = gql`
  mutation syncProductAvailabilityEvents(
    $input: [UpdateProductsAvailabilityEventInput!]
  ) {
    syncProductAvailabilityEvents(input: $input)
  }
`;

export const UPDATE_STORE_STATUS_EVENT = gql`
  mutation syncUpdateStoreEvents($input: [SyncUpdateStoreInput!]) {
    syncUpdateStoreEvents(input: $input)
  }
`;

export const BULK_UPDATE_PRODUCT_CATEGORY = gql`
  mutation bulkUpdateProductsCategory($input: BulkUpdateEntityCategoryInput) {
    bulkUpdateProductsCategory(input: $input)
  }
`;
