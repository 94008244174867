import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useEffect, useState, useCallback } from 'react';
import {
  CREATE_MODIFIERS,
  UPDATE_MODIFIERS,
  DELETE_MODIFIERS,
  GET_MODIFIERS_QUERY,
  GET_MODIFIER_QUERY,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import {
  UpdateModifierInput,
  CreateModifierInput,
  Modifier,
} from '@oolio-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';

export interface UseModifiersProps {
  modifiers: { [key: string]: Modifier };
  createModifiers: (createModifierInputs: CreateModifierInput[]) => void;
  error: string | undefined;
  loading: boolean;
  operation: Operation;
  createdIds: string[];
  deletedIds: string[];
  getAllModifiers: () => void;
  deleteModifiers: (ids: string[]) => void;
  updateModifiers: (data: UpdateModifierInput[]) => void;
}

export function useModifiers(modifierId?: string): UseModifiersProps {
  const [modifiers, setModifiers] = useState<Record<string, Modifier>>({});

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const [createdIds, setCreatedIds] = useState<string[]>([]);

  const [getModifiers, modifiersGetRequest] = useLazyQuery(
    GET_MODIFIERS_QUERY,
    {
      fetchPolicy: 'no-cache',
      onError: noopHandler,
    },
  );

  const [getModifier, modifierGetRequest] = useLazyQuery(GET_MODIFIER_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
  });

  const [createModifiers, createModifiersRequest] = useMutation(
    CREATE_MODIFIERS,
    {
      onError: noopHandler,
    },
  );

  const [deleteModifiers, deleteModifiersRequest] = useMutation(
    DELETE_MODIFIERS,
    {
      onError: noopHandler,
    },
  );

  const [updateModifiers, updateModifiersRequest] = useMutation(
    UPDATE_MODIFIERS,
    {
      onError: noopHandler,
    },
  );

  const setModifiersDataToState = useCallback((modifiers: Modifier[]) => {
    setModifiers(prev => {
      const tempModifiers = { ...prev };
      modifiers.forEach(eachModifier => {
        tempModifiers[eachModifier.id] = eachModifier;
      });
      return tempModifiers;
    });
  }, []);

  useEffect(() => {
    if (modifierId) {
      getModifier({ variables: { id: modifierId } });
      setOperation(Operation.READ);
    }
  }, [modifierId, getModifier]);

  useEffect(() => {
    if (modifiersGetRequest.data) {
      const modifiersData = modifiersGetRequest.data.modifiers as Modifier[];

      setModifiersDataToState(modifiersData);
    }
  }, [modifiersGetRequest.data, setModifiersDataToState]);

  const getAllModifiers = useCallback(() => {
    getModifiers();
    setOperation(Operation.READ);
  }, [getModifiers]);

  const createModifiersData = useCallback(
    (modifiers: CreateModifierInput[]) => {
      createModifiers({
        variables: {
          input: modifiers,
        },
      });
      setOperation(Operation.CREATE);
      setCreatedIds([]);
    },
    [createModifiers],
  );

  useEffect(() => {
    if (createModifiersRequest.data) {
      const createdModifiersData = createModifiersRequest.data
        .createModifiers as Modifier[];

      setModifiersDataToState(createdModifiersData);
      setCreatedIds(createdModifiersData.map(x => x.id));
    }
  }, [createModifiersRequest.data, setModifiersDataToState]);

  useEffect(() => {
    if (modifierGetRequest.data) {
      const modifiersData = modifierGetRequest.data.modifier as Modifier;

      setModifiersDataToState([modifiersData]);
    }
  }, [modifierGetRequest.data, setModifiersDataToState]);

  const deleteModifiersData = useCallback(
    (ids: string[]) => {
      deleteModifiers({
        variables: {
          ids,
        },
      });
      setOperation(Operation.DELETE);
      setDeletedIds(ids);
    },
    [deleteModifiers],
  );

  useEffect(() => {
    if (deleteModifiersRequest.data) {
      setModifiers(prev => {
        const tempModifiers = { ...prev };
        deletedIds.forEach(x => delete tempModifiers[x]);
        return tempModifiers;
      });
    }
  }, [deletedIds, deleteModifiersRequest.data]);

  useEffect(() => {
    if (updateModifiersRequest.data) {
      const updatedData = updateModifiersRequest.data
        .updateModifiers as Modifier[];

      setModifiersDataToState(updatedData);
    }
  }, [setModifiersDataToState, updateModifiersRequest.data]);

  const updateModifiersData = useCallback(
    (updateModifiersData: UpdateModifierInput[]) => {
      updateModifiers({
        variables: {
          input: updateModifiersData,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateModifiers],
  );

  const error: ApolloError | undefined =
    modifierGetRequest.error ||
    modifiersGetRequest.error ||
    createModifiersRequest.error ||
    deleteModifiersRequest.error ||
    updateModifiersRequest.error;

  const loading: boolean =
    modifierGetRequest.loading ||
    modifiersGetRequest.loading ||
    createModifiersRequest.loading ||
    deleteModifiersRequest.loading ||
    updateModifiersRequest.loading;

  return useMemo(
    () => ({
      modifiers,
      createModifiers: createModifiersData,
      deleteModifiers: deleteModifiersData,
      updateModifiers: updateModifiersData,
      getAllModifiers,
      error: error ? parseApolloError(error) : undefined,
      loading,
      operation,
      createdIds,
      deletedIds,
    }),
    [
      modifiers,
      error,
      loading,
      createModifiersData,
      operation,
      deleteModifiersData,
      updateModifiersData,
      getAllModifiers,
      createdIds,
      deletedIds,
    ],
  );
}
