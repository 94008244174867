import { ErrorPolicy } from '@apollo/client/core';
import {
  useApolloClient,
  useMutation,
  useReactiveVar,
} from '@apollo/client/react/hooks';
import {
  CloseOrderEvent,
  FeatureIDs,
  InitiateOrderEvent,
  InitiateRefundEvent,
  IntegrationApps,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderItemStatus,
  OrderStatus,
} from '@oolio-group/domain';
import { computeOrderState, splitComboItems } from '@oolio-group/order-helper';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import isEqual from 'lodash/isEqual';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useSyncExternalStore,
} from 'react';
import { SYNC_CDS_ORDER_EVENTS } from '../../graphql/syncEvents';
import {
  GET_ORDER,
  GET_ORDER_FRAGMENT,
  ORDER_SAVE,
} from '../../hooks/app/orders/graphql';
import { docketPrintedVar } from '../../state/cache';
import { userUtility } from '../../state/userUtility';
import { canPerformSaveAction } from '../../utils/cart';
import { parseApolloError } from '../../utils/errorHandlers';
import { generateOrderEvent } from '../../utils/orderEventHelper';
import kitchenOrderEvents from '../../utils/printerTemplates/kotEvents';
import { stripProperties } from '../../utils/stripObjectProps';
import { validateOrderEvents } from '../../utils/validateOrderEvents';
import { useCheckFeatureEnabled } from '../app/features/useCheckFeatureEnabled';
import { currentOrderActionObservable } from '../app/orders/ordersObservableUtils';
import { useSalesChannels } from '../app/salesChannels/useSalesChannels';
import { postSalesObservableForLogin } from '../app/usePostSalesNavigation';
import { useSession } from '../app/useSession';
import { useSettings } from '../app/useSettings';
import { extractCounter, useOrderNumber } from './useOrderNumber';
import { useTokenNumber } from './useTokenNumber';
import { useBatchedCallback } from '../useBatchedCallback';
import {
  OrderStore,
  OrderStoreState,
  globalOrderStore,
} from '../../store/OrderStore';
import { advancedKeypadObservable } from '../app/orders/ordersObservableUtils';

/**
 * Evaluates whether to send the events (pending / new) to server
 * or to keep them in local instance state
 * @param action
 */

const canSyncToServer = (action: OrderAction): boolean => {
  switch (action) {
    case OrderAction.ORDER_SAVE:
    case OrderAction.ORDER_VOID:
      return true;
    default:
      return false;
  }
};

export type OnSaveCompletedCallback = (order: Order | null) => void;

/**
 * Filter removed items (cancelled) from Order.
 * @param orderItems OrderItem
 */
const filterCancelledItems = (orderItems: OrderItem[]): OrderItem[] => {
  return orderItems.filter(item => item.status !== OrderItemStatus.CANCELLED);
};

export interface UseCartParams {
  status: {
    error: string;
    loading: boolean;
  };
  updateCart: <T extends OrderEvent>(
    action: OrderAction,
    input?: Omit<T, keyof OrderEvent>,
    eventId?: string,
    onSaveCompletedCallback?: OnSaveCompletedCallback,
  ) => void;
  discardChanges: () => void;
  resetCart: (
    postOrderCallback?: (cartId: string) => void,
    isTraining?: boolean,
  ) => Promise<string>;
  clearPriorPendingEvents: () => void;
  order: Order | undefined;
  isDirty: boolean;
  itemsChanged: boolean;
  getOrderData: (
    orderId: string,
    errorPolicy?: ErrorPolicy,
  ) => Promise<undefined | Order>;
  mergeCachedOrderWithEvents: (orderId: string) => Promise<void | Order>;
  setCartParams: (
    orderId?: string,
    orderTypeId?: string,
    tableId?: string,
    isExisting?: boolean,
  ) => void;
  initiateRefund: (prevOrder: Order, reason: string) => void;
  closeOrderCart: (option?: { status?: OrderStatus }) => void;
  openOrderCart: (orderId: string) => void;
  recomputeOrderFromServer: (orderId: string) => void;
  addEventsToCart: (events: OrderEvent[]) => void;
  getCartUnSavedStatus: () => boolean;
}

/**
 * Order management hook
 * Fetches order if orderId argument is given
 * Creates new order if orderId argument is not given
 * Provides updateCart method to update order with new actions
 *
 * Example:
 * ```
 * // create new order
 * const { order, updateCart } = useCart();
 *
 * const onPressAddItem = (item) => {
 *  updateCart(OrderAction.ORDER_ITEM_ADD_EVENT, item);
 * }
 *
 * // fetch order
 * const { order, updateCart, status } = useCart('xxx-xxx-xxx');
 *
 * ```
 * @param orderId
 */
export function useCart(store?: OrderStore): UseCartParams {
  const [session] = useSession();
  const params = useRef<{
    orderId?: string;
    orderTypeId?: string;
    tableId?: string;
  }>();

  const orderStore = store ?? globalOrderStore;
  const {
    error,
    pendingEvents,
    currentState,
    originalState,
    isDirty,
  }: OrderStoreState = useSyncExternalStore(
    orderStore.subscribe,
    orderStore.getSnapshot,
  );

  const { generate: generateOrderNumber } = useOrderNumber();
  const { getTokenNumber } = useTokenNumber();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenNumber, setTokenNumber] = useSettings<number>('tokenNumber');
  const onSaveCompleteHandlers = useRef<OnSaveCompletedCallback[]>([]);
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isCdsEnabled = useMemo(() => {
    return Boolean(
      isFeatureEnabled(FeatureIDs.CDS) && session.device?.customerDisplay?.id,
    );
  }, [isFeatureEnabled, session.device?.customerDisplay?.id]);

  const isCoursesEnabled = useMemo(() => {
    return Boolean(
      isFeatureEnabled(FeatureIDs.COURSES, session?.currentVenue?.id) &&
        session.deviceProfile?.enableCourses,
    );
  }, [
    isFeatureEnabled,
    session.currentVenue?.id,
    session.deviceProfile?.enableCourses,
  ]);

  const { inStoreSaleChannel, getSalesChannels, getInStoreSalesChannel } =
    useSalesChannels();

  const [saveOrder] = useMutation<{ saveOrder: Order }>(ORDER_SAVE, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      if (params.current?.orderId) {
        currentOrderActionObservable.next({
          orderId: params.current?.orderId,
          lastOrderAction: OrderAction.ORDER_SAVE,
          timestamp: Date.now(),
          isSyncComplete: false,
        });
      }
      const resolvedOrder = response.saveOrder;
      if (onSaveCompleteHandlers.current.length) {
        onSaveCompleteHandlers.current.forEach(callback => {
          callback && callback(resolvedOrder);
        });
        onSaveCompleteHandlers.current = [];
      }
    },
    ignoreResults: true,
  });
  const [syncCdsEvent] = useMutation(SYNC_CDS_ORDER_EVENTS, {
    ignoreResults: true,
  });
  const [counter, setCounter] = useSettings<number>('orderCounter');
  const docketPrintedEvent = useReactiveVar<OrderEvent | undefined>(
    docketPrintedVar,
  );

  useEffect(() => {
    // check if last printed docket is of this order
    if (
      docketPrintedEvent &&
      docketPrintedEvent?.orderId === currentState?.id
    ) {
      // update the order items docket fired flag
      orderStore.setState(prevState => {
        // update the order items docket fired flag
        if (
          prevState.currentState &&
          prevState.currentState.id === docketPrintedEvent?.orderId
        ) {
          const order = computeOrderState(
            [docketPrintedEvent],
            prevState.currentState,
          );
          saveOrder({ variables: { data: order } });
          return {
            ...prevState,
            currentState: order,
          };
        }
        return prevState;
      });
      // reset last docket printed var
      docketPrintedVar(undefined);
    }
  }, [docketPrintedEvent, saveOrder, currentState?.id, orderStore]);

  useEffect(() => {
    getSalesChannels();
  }, [getSalesChannels]);

  const client = useApolloClient();

  const getOrderNumberPrefix = useCallback(
    (prevOrder: Order) => {
      const oNum = prevOrder.orderNumber.split('-').splice(1).join('-');
      if (session.device) {
        return (
          session.device.returnPrefix?.split('-')[0] +
          '-' +
          (oNum || prevOrder.orderNumber)
        );
      } else return prevOrder.orderNumber;
    },
    [session.device],
  );

  const initiateRefund = useCallback(
    (prevOrder: Order, reason: string) => {
      const processPendingEvents = oldEvents => {
        if (oldEvents.length !== 0) {
          return oldEvents;
        }
        const action = OrderAction.ORDER_REFUND_INITIATE;
        const event = generateOrderEvent<InitiateRefundEvent>(
          action,
          {
            organizationId: session.currentOrganization?.id,
            venueId: session.currentVenue?.id,
            deviceId: session.device?.id,
            storeId: session.currentStore?.id,
            triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
          },
          {
            refundOf: prevOrder.id,
            reason,
            refundOrderNumber: getOrderNumberPrefix(prevOrder),
            ...(prevOrder?.integrationInfo?.app === IntegrationApps.OOM && {
              integrationApp: IntegrationApps.OOM,
            }),
          },
        );
        const order = computeOrderState([event], prevOrder);
        order.orderItems = filterCancelledItems(order.orderItems);
        isCdsEnabled &&
          syncCdsEvent({
            variables: {
              input: [event],
            },
          });

        const updated = [event];

        orderStore.setState(prevState => {
          return {
            ...prevState,
            originalState: order,
            currentState: order,
          };
        });
        return updated;
      };
      orderStore.setState('pendingEvents', processPendingEvents(pendingEvents));
    },
    [
      pendingEvents,
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      getOrderNumberPrefix,
      isCdsEnabled,
      syncCdsEvent,
      orderStore,
    ],
  );

  const mergeCachedOrderWithEvents = useCallback(
    async (orderId: string, errorPolicy?: ErrorPolicy) => {
      try {
        const response = await client.query<{ order: Order }>({
          query: GET_ORDER,
          variables: { orderId },
          fetchPolicy: 'cache-first',
          returnPartialData: false,
          errorPolicy: errorPolicy ?? 'none',
        });

        if (response.error) {
          orderStore.setState('error', parseApolloError(response.error));
        } else if (response.errors?.length) {
          orderStore.setState(
            'error',
            response.errors.map(error => error.message).join(', '),
          );
        } else {
          orderStore.setState('error', '');
          const order = response.data?.order;
          if (order) {
            let pendingOrderEvents: OrderEvent[] = [];
            const processPendingEvents = oldEvents => {
              if (
                [OrderStatus.COMPLETED, OrderStatus.VOID].includes(order.status)
              ) {
                return oldEvents.filter(event => event.orderId !== order.id);
              } else {
                const firstMatchedEvent = oldEvents.find(
                  event => event.orderId === order.id,
                );

                if (firstMatchedEvent)
                  firstMatchedEvent.previous = order.prevEventId;

                pendingOrderEvents = oldEvents.filter(
                  event => event.orderId === order.id,
                );

                return oldEvents;
              }
            };
            orderStore.setState(
              'pendingEvents',
              processPendingEvents(pendingEvents),
            );
            if (pendingOrderEvents.length > 0) {
              orderStore.setState(
                'currentState',
                computeOrderState(pendingOrderEvents, order),
              );
            } else {
              orderStore.setState('currentState', order);
            }
            orderStore.setState('originalState', order);
            return order;
          }
        }
      } catch (error) {
        orderStore.setState('error', (error as Error)?.message);
      }
    },
    [client, orderStore, pendingEvents],
  );

  const getOrderData = useCallback(
    async (orderId: string) => {
      try {
        let order =
          client.cache.readFragment<Order>({
            id: `Order:${orderId}`,
            fragment: GET_ORDER_FRAGMENT,
            returnPartialData: true,
          }) ?? undefined;
        const isOnAccountOrder = order?.status === OrderStatus.ON_ACCOUNT;
        // On Account check is used when we've disabled order polling from BO
        // If we disabled order polling then we don't have a way to update cache for on account orders
        // On account events calculated on worker so we can't store it to cache
        if (!order || isEmpty(order) || isOnAccountOrder) {
          const response = await client.query<{ order: Order }>({
            query: GET_ORDER,
            variables: { orderId },
            fetchPolicy: isOnAccountOrder ? 'network-only' : 'cache-first',
            returnPartialData: false,
          });
          if (response.error) {
            orderStore.setState('error', parseApolloError(response.error));
          } else if (response.errors?.length) {
            orderStore.setState(
              'error',
              response.errors.map(error => error.message).join(', '),
            );
          } else {
            orderStore.setState('error', '');
            order = response.data?.order;
            return order;
          }
        } else {
          return order;
        }
      } catch (error) {
        orderStore.setState('error', (error as Error)?.message);
      }
      return undefined;
    },
    [client, orderStore],
  );

  const incrementTokenNumber = useCallback(async () => {
    const deviceSettings = session?.device;
    const istTokenEnabled = deviceSettings?.isTokenNumberActive;
    let currentTokenNumber = (await getTokenNumber()) as number;
    const range = session?.device?.tokenSettings?.tokenRange;
    if (istTokenEnabled && Number.isInteger(currentTokenNumber)) {
      if (!Number.isInteger(currentTokenNumber)) {
        currentTokenNumber = range?.start || 0;
      } else if (!range?.end) {
        // if end range is undefined
        currentTokenNumber = currentTokenNumber + 1;
      } else if (range?.end && currentTokenNumber < range?.end) {
        // if end range is greater than token number
        currentTokenNumber = currentTokenNumber + 1;
      } else if (range?.end && currentTokenNumber >= range?.end) {
        // if current token number exceeded range
        currentTokenNumber = range.start || 0;
      }
      setTokenNumber(currentTokenNumber);
    } else if (istTokenEnabled && Number.isInteger(range?.start)) {
      setTokenNumber(range?.start as number);
    }
  }, [session, getTokenNumber, setTokenNumber]);

  const resetCart = useBatchedCallback(
    async (
      postOrderCallback?: (cartId: string) => void,
      isTraining?: boolean,
    ) => {
      const isTrainingTransaction =
        isTraining ?? !!session.device?.trainingMode;
      if (!session) {
        return '';
      }
      const event = generateOrderEvent<InitiateOrderEvent>(
        OrderAction.ORDER_INITIATE,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
        },
        {
          tableId: params.current?.tableId,
          orderTypeId:
            params.current?.orderTypeId ||
            session.deviceProfile?.defaultOrderType?.id,
          orderNumber: await generateOrderNumber(),
          salesChannelId:
            inStoreSaleChannel?.id || (await getInStoreSalesChannel())?.id,
          tokenNumber: await getTokenNumber(),
          triggeredUserName: userUtility.posUser?.name,
          triggeredDeviceName: session.device?.name,
          isTraining: isTrainingTransaction,
        },
      );

      const order = computeOrderState([event]);

      orderStore.setState(prevState => {
        return {
          ...prevState,
          currentState: order,
          originalState: order,
          pendingEvents: [event],
        };
      });
      postOrderCallback && postOrderCallback(order.id);

      saveOrder({ variables: { data: order } });
      isCdsEnabled &&
        syncCdsEvent({
          variables: {
            input: [event],
          },
        });

      currentOrderActionObservable.next({
        orderId: order.id,
        lastOrderAction: OrderAction.ORDER_INITIATE,
        lastEventId: event.id,
        timestamp: Date.now(),
        isSyncComplete: false,
      });
      return order.id;
    },
    [
      session,
      generateOrderNumber,
      inStoreSaleChannel?.id,
      getTokenNumber,
      orderStore,
      saveOrder,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  const updatedComboItemsForCourse = useCallback(
    (orderItems: OrderItem[]) => {
      const isComboInOrder = orderItems?.some(
        i => i.comboItems?.length || i.parentCombo?.id,
      );
      if (isCoursesEnabled && isComboInOrder) {
        return splitComboItems(orderItems);
      }
      return orderItems;
    },
    [isCoursesEnabled],
  );

  /**
   * Perform cart update actions
   * Events are stored until the next save point
   */

  const updateCart = useBatchedCallback(
    <T extends OrderEvent>(
      action: OrderAction,
      input?: Omit<T, keyof OrderEvent>,
      eventId?: string,
      onSaveCompletedCallback?: OnSaveCompletedCallback,
    ) => {
      let cdsEvent: OrderEvent | undefined = undefined;
      const prevState = orderStore.getSnapshot();
      if (!prevState.currentState) {
        orderStore.setState(
          'error',
          'Actions are not permitted when order is undefined',
        );
        return prevState;
      }

      const eventInput = stripProperties({ ...input }, '__typename');
      const event = generateOrderEvent(
        action,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
          ...(prevState.currentState.isOnline && {
            integrationApp: IntegrationApps.DOSHII,
          }),
        },
        {
          ...eventInput,
          orderId: prevState.currentState?.id,
          previous: prevState.currentState?.prevEventId,
          ...(prevState.currentState?.integrationInfo?.app ===
            IntegrationApps.OOM && {
            integrationApp: IntegrationApps.OOM,
          }),
        },
        eventId,
      );

      if (!!cdsEvent) {
        // Fix async issue of callback inside setState sometime run twice for a single updateCart call
        event.id = (cdsEvent as OrderEvent).id;
      }
      const order = computeOrderState([event], prevState.currentState);
      order.orderItems = filterCancelledItems(order.orderItems);
      if (isCoursesEnabled) {
        order.orderItems = updatedComboItemsForCourse(order.orderItems);
      }

      isCdsEnabled &&
        !cdsEvent &&
        syncCdsEvent({
          variables: {
            input: [event],
          },
        });
      cdsEvent = event;

      /**
       * Accumulate events until save order action is performed
       * Using setPendingEvents this way helps avoid add pendingEvents to dependency list
       * avoiding updateCart changing every time it is called since it modifies pendingEvents
       */
      const getUpdatedEvents = oldEvents => {
        if (!event.previous && oldEvents.length == 1) {
          event.previous = oldEvents[0].id;
        }
        const updatedEvents = [...oldEvents, event];
        return updatedEvents;
      };
      const updatedEvents = getUpdatedEvents(prevState.pendingEvents);

      const isSaveAction = action === OrderAction.ORDER_SAVE;

      // if it is a save action and there are no previous events in the current cart state
      //    and cart has some items which are not sent to kitchen(which are to be sent)
      //    and had some payment attempts(for card payment cases) made
      //    then, add save action on the order
      const skipSingleSaveAction =
        isSaveAction &&
        prevState.pendingEvents.length === 0 &&
        !canPerformSaveAction(order);

      // When there are no changes made to order
      if (skipSingleSaveAction) {
        orderStore.setState(prevState => {
          return {
            ...prevState,
            currentState: order,
            isDirty: true,
            pendingEvents: [],
          };
        });
        return [];
      } else if (canSyncToServer(action)) {
        orderStore.setState(prevState => {
          return {
            ...prevState,
            isDirty: false,
            originalState: order,
            currentState: order,
            pendingEvents: [],
          };
        });
        saveOrder({ variables: { data: order } });
        if (extractCounter(order.orderNumber) > (counter || 0)) {
          setCounter(extractCounter(order.orderNumber));
          incrementTokenNumber();
        }

        if (action !== OrderAction.ORDER_SAVE) {
          currentOrderActionObservable.next({
            orderId: order.id,
            lastOrderAction: action,
            lastEventId: event.id,
            timestamp: Date.now(),
            isSyncComplete: false,
          });
        }

        onSaveCompleteHandlers.current.push(() => {
          kitchenOrderEvents.publishToKotUtil({
            orderId: order.id,
            preEvents: updatedEvents,
          });
        });

        onSaveCompletedCallback &&
          onSaveCompleteHandlers.current.push(onSaveCompletedCallback);

        return [];
      } else {
        orderStore.setState(state => {
          return {
            ...state,
            currentState: order,
            pendingEvents: updatedEvents,
            error: '',
            isDirty: true,
          };
        });
      }
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      syncCdsEvent,
      saveOrder,
      counter,
      setCounter,
      isCdsEnabled,
      incrementTokenNumber,
      updatedComboItemsForCourse,
    ],
  );

  /**
   * Set current state back to original before performing cart updates
   * This method only discards actions up to the last save point
   */
  const discardChanges = useCallback(() => {
    // ORDER_INITIATE event shouldn't be discarded
    const processPendingEvents = old => {
      const firstEvent = old[0];
      if (firstEvent?.action === OrderAction.ORDER_INITIATE) {
        return [firstEvent];
      }
      return [];
    };
    orderStore.setState(prevState => {
      return {
        ...prevState,
        pendingEvents: processPendingEvents(prevState.pendingEvents),
        currentState: originalState,
        isDirty: false,
      };
    });
  }, [orderStore, originalState]);

  const recomputeOrderFromServer = useCallback(
    async (orderId: string): Promise<void> => {
      let order: Order;
      if (orderId == currentState?.id) {
        order = currentState;
      } else {
        order = (await getOrderData(orderId)) as Order;
      }
      const events = await validateOrderEvents(order as Order);
      if (order?.status !== OrderStatus.CREATED && events.length) {
        const validOrder = computeOrderState(
          events.concat(pendingEvents.filter(x => x.orderId === order?.id)),
        );
        orderStore.setState(prevState => {
          return {
            ...prevState,
            currentState: validOrder,
            originalState: validOrder,
          };
        });
      }
    },
    [currentState, getOrderData, orderStore, pendingEvents],
  );

  // const orderId = params?.current?.orderId;

  // const { data: resolvedOrder } = useQuery<{ order: Order }>(GET_ORDER, {
  //   variables: { orderId },
  //   skip: !orderId,
  //   fetchPolicy: 'cache-only',
  //   returnPartialData: true,
  // });

  // useEffect(() => {
  //   if (resolvedOrder) {
  //     if (
  //       resolvedOrder.order?.prevEventId !==
  //         orderStore.getSnapshot().currentState?.prevEventId ||
  //       resolvedOrder?.order?.eventsCount !==
  //         orderStore.getSnapshot().currentState?.eventsCount
  //     ) {
  //       orderStore.setState(prevState => {
  //         return {
  //           ...prevState,
  //           originalState: resolvedOrder.order,
  //           currentState: computeOrderState(pendingEvents, resolvedOrder.order),
  //         };
  //       });
  //     }
  //   }
  // }, [client.cache, orderStore, resolvedOrder, store, orderId, pendingEvents]);

  const setCartParams = useBatchedCallback(
    async (
      orderId?: string,
      orderTypeId?: string,
      tableId?: string,
      isExisting?: boolean,
    ): Promise<void> => {
      params.current = {
        orderId,
        orderTypeId: !isNil(orderTypeId)
          ? orderTypeId
          : params.current?.orderTypeId,
        tableId: !isNil(tableId) ? tableId : params.current?.tableId,
      };
      currentOrderActionObservable.next(undefined);
      postSalesObservableForLogin.next(false);
      // fetch order
      if (orderId && isExisting) {
        let order = await getOrderData(orderId);
        let orderItems = order?.orderItems || [];
        // TODO: Verify if this is the right place and time to check order integrity
        const events = await validateOrderEvents(order as Order);
        const filterEvents = pendingEvents.filter(
          event => event.orderId === orderId,
        );
        const isOrderContainCombos = order?.orderItems.some(
          i => i.comboItems?.length,
        );
        if (order?.status !== OrderStatus.CREATED && events.length) {
          order = computeOrderState(events.concat(filterEvents));
        }
        if (
          order?.orderItems?.length &&
          isCoursesEnabled &&
          isOrderContainCombos
        ) {
          orderItems = cloneDeep(splitComboItems(order?.orderItems));
        }
        orderStore.setState(prevState => {
          return {
            ...prevState,
            originalState: { ...order, orderItems } as Order,
            pendingEvents: filterEvents,
            currentState: { ...order, orderItems } as Order,
          };
        });
      }
    },
    [getOrderData, pendingEvents],
  );

  const getCartUnSavedStatus = useCallback(() => {
    if (!isDirty) {
      // If we don't have unsaved changes, then we don't need to do anything
      return false;
    }

    if (
      currentState &&
      currentState.status !== OrderStatus.IN_PROGRESS &&
      currentState?.orderItems.length === 0
    ) {
      // If we don't have unsaved changes, then we don't need to do anything
      return false;
    }

    if (currentState && currentState.status === OrderStatus.COMPLETED) {
      // If we have completed order, then we don't need to do anything
      return false;
    }
    return true;
  }, [currentState, isDirty]);

  const clearPriorPendingEvents = useCallback(() => {
    orderStore.setState('pendingEvents', []);
  }, [orderStore]);

  /**
   * @description close the cart and navigate to Idle screen on Customer Display device
   * @param {OrderStatus} status the status of order to close. if IN_PROGRESS, only clear the cart don't do the navigation
   */
  const closeOrderCart = useCallback(
    async (option?: { status?: OrderStatus }) => {
      const event = generateOrderEvent<CloseOrderEvent>(
        OrderAction.ORDER_CLOSE,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
        },
        {
          orderId: currentState?.id,
          status: option?.status,
        },
      );
      advancedKeypadObservable.next(0);
      await (isCdsEnabled &&
        syncCdsEvent({
          variables: {
            input: [event],
          },
        }));
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      currentState?.id,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  const openOrderCart = useCallback(
    async (orderId: string) => {
      const event = generateOrderEvent(
        OrderAction.ORDER_OPEN,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
        },
        {
          orderId: orderId,
        },
      );
      isCdsEnabled &&
        (await syncCdsEvent({
          variables: {
            input: [event],
          },
        }));
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  const addEventsToCart = useCallback(
    (events: OrderEvent[]) => {
      /**
       * Using setOrder this way helps avoid add order to dependency list
       * avoiding updateCart changing every time it is called since it modifies order
       */
      const prevState = orderStore.getSnapshot();
      if (!prevState.currentState) {
        orderStore.setState(
          'error',
          'Actions are not permitted when order is undefined',
        );
        return prevState;
      } else {
        orderStore.setState('error', '');
      }

      const order = computeOrderState(events, prevState.currentState);
      order.orderItems = filterCancelledItems(order.orderItems);

      // Reusing logic from above, to set order state and initiate docket print.
      orderStore.setState(state => {
        return {
          ...state,
          originalState: order,
          isDirty: false,
        };
      });
      saveOrder({ variables: { data: order } });

      if (extractCounter(order.orderNumber) > (counter || 0))
        setCounter(extractCounter(order.orderNumber));

      // Initiate print if the order is Completed.
      if (order.status === OrderStatus.COMPLETED) {
        onSaveCompleteHandlers.current.push(() => {
          kitchenOrderEvents.publishToKotUtil({
            orderId: order.id,
            preEvents: [],
          });
        });
      }

      orderStore.setState(state => {
        return {
          ...state,
          currentState: order,
        };
      });

      isCdsEnabled &&
        syncCdsEvent({
          variables: {
            input: events,
          },
        });
    },
    [orderStore, saveOrder, counter, setCounter, isCdsEnabled, syncCdsEvent],
  );

  const statusObj = useMemo(
    () => ({
      error,
      loading: false, // always be false as the queries above are synchronous
    }),
    [error],
  );

  const itemsChanged = useMemo(() => {
    return !isEqual(currentState?.orderItems, originalState?.orderItems);
  }, [currentState?.orderItems, originalState?.orderItems]);

  return {
    status: statusObj,
    updateCart,
    discardChanges,
    resetCart,
    clearPriorPendingEvents,
    order: currentState,
    itemsChanged: itemsChanged,
    getOrderData,
    mergeCachedOrderWithEvents,
    setCartParams,
    isDirty,
    initiateRefund,
    closeOrderCart,
    openOrderCart,
    recomputeOrderFromServer,
    addEventsToCart,
    getCartUnSavedStatus,
  };
}
