import {
  Product,
  CreateProductInput,
  CreateVariantProductInput,
  ProductPricingInput,
  Currency,
  Money,
  OnboardingArea,
  OnboardingCheckList,
  OnboardingSection,
  OnboardingAction,
  Variant,
  IMPORT_ENTITY_STATUS,
  ProductPricing,
  NON_CATEGORIZED_ITEMS,
  DEFAULT_PRICING_GROUP,
  UpdateProductInput,
} from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import { useNotification } from '../../../../hooks/Notification';
import {
  useCurrency,
  useLocalization,
  useTranslation,
} from '@oolio-group/localization';
import { useVariants } from '../../../../hooks/app/variants/useVariants';
import { useProducts } from '../../../../hooks/app/products/useProducts';
import {
  CREATE_AND_UPDATE_PRODUCT_PRICINGS_IN_PRODUCT_LIST_SCREEN,
  PRODUCT_DETAIL_IN_PRODUCT_LIST_SCREEN,
  PRODUCTS_IN_PRODUCT_LIST_SCREEN,
} from '../../../../hooks/app/products/graphql';
import {
  VARIANTS_IN_PRODUCT_LIST_SCREEN,
  VARIANT_DETAIL_IN_PRODUCT_LIST_SCREEN,
} from '../../../../hooks/app/variants/graphql';
import { PRICING_GROUP_IN_PRODUCT_LIST } from '../../../../graphql/pricingGroups';
import { useTaxes } from '../../../../hooks/app/useTaxes';
import { useProductPricings } from '../../../../hooks/app/productPricings/useProductPricings';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import {
  CUSTOM_CHANNELS_STORES_OPTIONS_QUERY,
  useAvailabilityOptions,
} from '../../../../hooks/app/useAvailabilityOptions';
import { usePrinterProfiles } from '../../../../hooks/app/usePrinterProfiles';
import { useOnboarding } from '../../../../hooks/app/useOnboarding';
import orderBy from 'lodash/orderBy';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  CreateProductModal,
  CreateProductForm,
} from './Modals/CreateProductModal';
import { useProductTypes } from '../../../../hooks/app/useProductTypes';
import {
  ProductHeaderFilters,
  filterBySearchQuery,
  filterByPrinterProfile,
  filterByCategory,
  filterByStore,
  filterByOptionGroups,
} from './ProductHeaderFilters';
import { useIsFocused } from '@react-navigation/native';
import { ProductListItem } from './types';
import {
  CATEGORIES_AS_OPTIONS,
  CATEGORIES_WITH_ENTITY_IDS,
  useCategories,
} from '../../../../hooks/app/categories/useCategories';
import { getLocaleEntity } from '@oolio-group/client-utils';
import sortBy from 'lodash/sortBy';
import ProductListView from './ListView/ProductListView';
import { ProductBulkOptions } from './ProductBulkOptions';
import styles from './ProductList.styles';
import theme from '../../../../common/default-theme';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import MessageAction from '../../../../components/Office/MessageAction/MessageAction';
import { useImportEntityInterval } from '../../../../hooks/app/importEntities/useImportEntityInterval';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../constants';
import {
  useModifierGroups,
  MODIFIER_GROUPS_AS_OPTIONS,
} from '../../../../hooks/app/modifierGroups/useModifierGroups';
import LoadingOverlay from '../../../../components/Shared/Loaders/LoadingOverlay';
import { getDefaultPricingInput } from '../../../../utils/productHelper';
import { keyBy } from 'lodash';

export type allProductsforDataGridKeys =
  | 'id'
  | 'name'
  | 'productTypeId'
  | 'price'
  | 'tax'
  | 'isSellable'
  | 'isVariant'
  | 'isVariantProduct';

export interface FilterValue {
  searchString: string;
  category: string;
  store: string;
  printerProfilesFilter: string[];
  optionGroups: string[];
}

const defaultFilter: FilterValue = {
  category: '',
  searchString: '',
  store: '',
  printerProfilesFilter: [],
  optionGroups: [],
};

export const getDefaultInfoForProduct = (product: Product): ProductPricing => {
  const defaultPG = product?.pricingGroups?.filter(
    pricingGroup => pricingGroup.name === DEFAULT_PRICING_GROUP,
  );
  const defaultPrice = defaultPG?.[0]?.prices?.[0] as ProductPricing;
  return defaultPrice;
};

const formatProductItem = (prod: Product, productPricing: ProductPricing) => {
  const price = productPricing?.sellingPrice?.amount || 0;
  const priceId = productPricing?.id || '';
  const taxId = productPricing?.sellingTax?.id || '';
  const currency = productPricing?.sellingPrice?.currency || Currency.AUD;
  return {
    id: prod.id,
    isSellable: prod.isSellable,
    isVariant: false,
    isVariantProduct: false,
    name: prod.name,
    price: price.toString() || '',
    tax: taxId,
    defaultPriceId: priceId,
    isCombo: prod?.isCombo || false,
    comboType: prod?.comboType,
    currency,
    printerProfiles: prod?.printerProfiles
      ? prod?.printerProfiles.map(item => item.id)
      : [],
    barcode: prod?.barcode,
  } as ProductListItem;
};

const getEntityId = (entity: Product | Variant | ProductListItem) => entity?.id;

export const ProductsList: React.FC = () => {
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { currency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { locale } = useLocalization();
  const { updateOnboardingStatus } = useOnboarding();
  const navigation = useNavigation();
  const [productItemMaps, setProductItemMaps] = useState<
    Record<string, ProductListItem>
  >({});

  const [productPricingMaps, setProductPricingMaps] = useState<
    Record<string, ProductPricing>
  >({});

  const productItemsOriginalRef = React.useRef<ProductListItem[]>([]);
  const [filter, setFilter] = useState<FilterValue>(defaultFilter);
  const [isImportProductFeatureEnabled, setImportProductFeatureFlag] =
    useState<boolean>(false);
  const [categoryEntityIds, setCategoryEntityIds] = useState<string[]>([]);

  const {
    category,
    searchString,
    store,
    printerProfilesFilter,
    optionGroups: optionGroupsFilters,
  } = filter;
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [creatingProduct, setCreatingProduct] = useState<
    Partial<CreateProductForm>
  >({});

  const [recentCreatedProductVariants, setRecentCreatedProductVariants] =
    useState<Record<string, Product>>({});

  const {
    categoryMaps,
    getCategories: getCategoriesOptions,
    error: categoriesError,
    loading: categoriesLoading,
    getCategorizedItems,
    categorizedItemIds,
  } = useCategories({
    customFragment: CATEGORIES_AS_OPTIONS,
  });

  const {
    category: selectedCategory,
    getCategory,
    loading: getCategoryLoading,
  } = useCategories({
    customFragment: CATEGORIES_WITH_ENTITY_IDS,
  });

  const {
    products,
    error: prodErr,
    loading: prodLoading,
    updateProducts,
    deleteProducts,
    bulkUpdateProductsCategory,
    getAllProducts,
    getProductIdsByStore,
    productIdsOfStoreMaps,
    getProductIdsByModGroups,
    productIdsOfModGroups,
  } = useProducts(undefined, PRODUCTS_IN_PRODUCT_LIST_SCREEN);

  const {
    getProductById,
    loading: productDetailLoading,
    products: productDetailMaps,
    copyProduct,
    createProduct,
  } = useProducts(undefined, PRODUCT_DETAIL_IN_PRODUCT_LIST_SCREEN);

  const {
    variants,
    error: variantErr,
    getAllVariants,
    loading: varLoading,
    updateVariants,
    deleteVariants,
    bulkUpdateVariantsCategory,
    deleteVariantProductInCache,
    variantIdsOfStoreMaps,
    getVariantIdsByStore,
  } = useVariants(undefined, VARIANTS_IN_PRODUCT_LIST_SCREEN);

  const {
    createVariant,
    copyVariant,
    getVariantData,
    loading: variantDetailLoading,
    variants: variantDetailMaps,
  } = useVariants(undefined, VARIANT_DETAIL_IN_PRODUCT_LIST_SCREEN);

  useEffect(() => {
    async function getImportProductFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.IMPORT_PRODUCT,
      );
      setImportProductFeatureFlag(isEnabled);
    }
    getImportProductFlag();
  }, []);

  useEffect(() => {
    if (!filter.category) return;
    let categoryEntityIds: string[] = [];
    const productItemsRef = productItemsOriginalRef.current;
    if (filter.category === NON_CATEGORIZED_ITEMS) {
      const categorizedItemIdSet = new Set(categorizedItemIds);
      const nonCategorizedProductItemIds = productItemsRef
        .filter(
          item => !item.isVariantProduct && !categorizedItemIdSet.has(item.id),
        )
        .map(getEntityId);
      categoryEntityIds = nonCategorizedProductItemIds;
    } else {
      const productIdsSet = (selectedCategory?.products || []).map(getEntityId);
      const variantIdsSet = (selectedCategory?.variants || []).map(getEntityId);
      categoryEntityIds = productIdsSet.concat(variantIdsSet);
    }
    setCategoryEntityIds(categoryEntityIds);
  }, [
    categorizedItemIds,
    filter.category,
    selectedCategory?.products,
    selectedCategory?.variants,
  ]);

  const productIdsOfStore = useMemo(() => {
    const normalProductIds = new Set(productIdsOfStoreMaps[filter.store] || []);
    const variantIds = new Set(variantIdsOfStoreMaps[filter.store] || []);
    return new Set([
      ...Array.from(normalProductIds),
      ...Array.from(variantIds),
    ]);
  }, [filter.store, productIdsOfStoreMaps, variantIdsOfStoreMaps]);

  const allProductsArray = useMemo(() => {
    const productItems = Object.values(productItemMaps);
    const filteredProductMaps = new Map<string, ProductListItem>();

    productItems.forEach(productItem => {
      let isValid = true;
      if (searchString) {
        isValid = filterBySearchQuery(productItem, searchString);
      }
      if (isValid && category) {
        isValid = filterByCategory(productItem, new Set(categoryEntityIds));
      }
      if (isValid && store) {
        isValid = filterByStore(productItem, productIdsOfStore);
      }
      if (isValid && printerProfilesFilter.length > 0) {
        isValid = filterByPrinterProfile(
          productItem,
          new Set(printerProfilesFilter),
        );
      }

      if (isValid && optionGroupsFilters.length > 0) {
        const validProductIds = new Set(productIdsOfModGroups);
        const hasNoneOption = optionGroupsFilters.includes('none');
        isValid = filterByOptionGroups(
          productItem,
          validProductIds,
          hasNoneOption,
        );
      }
      if (isValid) {
        filteredProductMaps.set(productItem.id, productItem);
      }
    });

    // check whether variants valid when child products are filtered
    const validProductItems = productItems
      .map(item => {
        const valid = filteredProductMaps.has(item.id);
        if (valid && !item.isVariantProduct) return item;
        if (item.isVariant) {
          const filteredProductIds = (item.productIds || []).filter(productId =>
            filteredProductMaps.has(productId),
          );
          if (filteredProductIds?.length) {
            return {
              ...item,
              filteredProductIds,
            };
          }
        }
        return null;
      })
      .filter(Boolean) as ProductListItem[];

    const sortedItems = orderBy(validProductItems, ['name'], ['asc']);
    return sortedItems;
  }, [
    productItemMaps,
    searchString,
    category,
    store,
    printerProfilesFilter,
    optionGroupsFilters,
    categoryEntityIds,
    productIdsOfStore,
    productIdsOfModGroups,
  ]);

  const selectedProducts = useMemo(() => {
    return allProductsArray.filter(x => x && x.isSelected);
  }, [allProductsArray]);

  const changedProducts = useMemo(() => {
    return Object.values(productItemMaps).filter(
      x => x && !x.isVariant && x.isChanged,
    );
  }, [productItemMaps]);

  const {
    importProductDetailState,
    isImportEntityRunning,
    clearState: clearImportIntervalState,
    getImportProductDetails,
  } = useImportEntityInterval(true);

  const {
    error: optionsGroupsError,
    loading: optionsGroupsLoading,
    modifierGroups: optionsGroups,
    getAllModifierGroups,
  } = useModifierGroups(undefined, MODIFIER_GROUPS_AS_OPTIONS);

  const onProductPricingUpdateCompleted = useCallback(
    (updatedProducts: Product[]) => {
      const updateProductPricingMaps = updatedProducts.reduce(
        (maps, product) => {
          const pricing = getDefaultInfoForProduct(product);
          maps[product.id] = pricing;
          return maps;
        },
        {} as Record<string, ProductPricing>,
      );
      setProductPricingMaps(prev => ({
        ...prev,
        ...updateProductPricingMaps,
      }));
    },
    [],
  );

  const {
    error: errorPP,
    loading: loadingPP,
    update: updateProductPricings,
  } = useProductPricings({
    customFragment: CREATE_AND_UPDATE_PRODUCT_PRICINGS_IN_PRODUCT_LIST_SCREEN,
    onUpdateCompleted: onProductPricingUpdateCompleted,
  });

  const {
    loading: optionsLoading,
    salesChannels,
    stores,
  } = useAvailabilityOptions(CUSTOM_CHANNELS_STORES_OPTIONS_QUERY);

  const {
    loading: defaultPricingGroupLoading,
    error: PGErr,
    getDefaultPricingGroup,
    defaultPricingGroup,
  } = usePricingGroups({
    customFragment: PRICING_GROUP_IN_PRODUCT_LIST,
  });

  useEffect(() => {
    const prices = defaultPricingGroup?.prices || [];
    const productPricings = prices.reduce((acc, price) => {
      if (price?.product?.id) {
        acc[price.product.id] = price;
      }
      return acc;
    }, {} as Record<string, ProductPricing>);
    setProductPricingMaps(productPricings);
  }, [defaultPricingGroup]);

  const { taxesOptions, error: taxesErr } = useTaxes();

  const {
    productTypes,
    getProductTypes,
    error: productTypesErr,
  } = useProductTypes({ fetchPolicy: 'cache-first' });

  const {
    error: errorPrintProfile,
    loading: loadingPrintProfile,
    printerProfiles,
    getPrinterProfiles,
  } = usePrinterProfiles();

  const error =
    prodErr ||
    variantErr ||
    PGErr ||
    errorPP ||
    taxesErr ||
    productTypesErr ||
    errorPrintProfile ||
    categoriesError ||
    optionsGroupsError;

  const loading =
    optionsLoading ||
    loadingPP ||
    varLoading ||
    prodLoading ||
    loadingPrintProfile ||
    defaultPricingGroupLoading ||
    categoriesLoading ||
    optionsGroupsLoading ||
    getCategoryLoading;

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [productTypes]);

  const printerProfileOptions = useMemo(() => {
    return Object.values(printerProfiles).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [printerProfiles]);

  const optionsGroupOptions = useMemo(() => {
    return Object.values(optionsGroups).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [optionsGroups]);

  const categoryOptions = useMemo(() => {
    return sortBy(Object.values(categoryMaps), category =>
      category?.name?.toLowerCase(),
    ).map(category => ({
      label: getLocaleEntity(category, locale.languageTag).name,
      value: category?.id,
    }));
  }, [categoryMaps, locale.languageTag]);

  const unSelectProducts = useCallback(() => {
    if (selectedProducts.length) {
      setProductItemMaps(prev => {
        const tempPrev = { ...prev };
        selectedProducts.forEach(x => {
          tempPrev[x.id] = {
            ...tempPrev[x.id],
            isSelected: false,
          };
        });
        return tempPrev;
      });
    } else {
      setProductItemMaps(prev => {
        const tempPrev = { ...prev };
        allProductsArray.forEach(x => {
          tempPrev[x.id] = {
            ...tempPrev[x.id],
            isSelected: true,
          };
        });
        return tempPrev;
      });
    }
  }, [selectedProducts, allProductsArray]);

  const clearState = useCallback(() => {
    clearImportIntervalState(false);
  }, [clearImportIntervalState]);

  const isImportCompleted = useMemo(() => {
    return (
      [IMPORT_ENTITY_STATUS.COMPLETED, IMPORT_ENTITY_STATUS.FAILED].includes(
        importProductDetailState?.status as IMPORT_ENTITY_STATUS,
      ) || false
    );
  }, [importProductDetailState?.status]);

  const getProductsList = useCallback(() => {
    getAllVariants();
    getAllProducts();
    getCategoriesOptions();
  }, [getAllProducts, getAllVariants, getCategoriesOptions]);

  useEffect(() => {
    if (isFocused) {
      getProductTypes();
      getPrinterProfiles();
      getImportProductDetails();
      getAllModifierGroups();
      getProductsList();
      getDefaultPricingGroup();
    }
  }, [
    getPrinterProfiles,
    getProductTypes,
    getImportProductDetails,
    isFocused,
    getAllModifierGroups,
    getProductsList,
    getDefaultPricingGroup,
  ]);

  useEffect(() => {
    if (isImportCompleted) {
      getProductsList();
    }
    //Cleared import message when we redirect to another page
    return () => {
      if (isImportCompleted) {
        clearState();
      }
    };
  }, [getProductsList, isImportCompleted, clearState]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    // load product data on load
    const variantsList = Object.values(variants);
    const allProducts = Object.values({
      ...recentCreatedProductVariants,
      ...products,
    });
    const productItemMaps = {} as Record<string, ProductListItem>;
    allProducts.forEach(prod => {
      const pricing = productPricingMaps[prod.id];
      productItemMaps[prod.id] = formatProductItem(prod, pricing);
    });
    variantsList.forEach(variant => {
      productItemMaps[variant.id] = {
        id: variant.id,
        isVariant: true,
        isVariantProduct: false,
        name: variant.name,
        isSelected: false,
        price: '0',
        productIds: variant.products.map(x => x.id),
        filteredProductIds: variant.products.map(x => x.id),
        printerProfiles: variant?.printerProfiles
          ? variant?.printerProfiles.map(item => item.id)
          : [],
        barcode: variant?.barcode,
      };
      variant.products.forEach(variantProduct => {
        const productId = variantProduct.id;
        productItemMaps[productId] = {
          ...productItemMaps[productId],
          isVariantProduct: true,
          variantId: variant.id,
        };
      });
    });
    setProductItemMaps(productItemMaps);
    productItemsOriginalRef.current = Object.values(productItemMaps);
  }, [variants, productPricingMaps, recentCreatedProductVariants, products]);

  useEffect(() => {
    if (!filter.category) return;
    if (filter.category == NON_CATEGORIZED_ITEMS) {
      getCategorizedItems();
    } else {
      getCategory(filter.category);
    }
  }, [filter.category, getCategorizedItems, getCategory]);

  useEffect(() => {
    if (filter.store) {
      getProductIdsByStore(filter.store);
      getVariantIdsByStore(filter.store);
    }
  }, [filter.store, getProductIdsByStore, getVariantIdsByStore]);

  useEffect(() => {
    if (filter?.optionGroups?.length) {
      getProductIdsByModGroups(filter.optionGroups);
    }
  }, [filter.optionGroups, getProductIdsByModGroups]);

  const deleteProductItem = useCallback(
    productItemId => {
      const item = productItemMaps[productItemId];
      const selectedProds: string[] = [];
      const selectedVars: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let updateVariantProductCallback: any = undefined;
      if (item?.isVariant) {
        item.productIds?.forEach(eachProd => {
          productItemMaps?.[eachProd] &&
            selectedProds.push(productItemMaps[eachProd].id);
        });
        selectedVars.push(item.id);
      } else {
        productItemMaps?.[item.id] &&
          selectedProds.push(productItemMaps[item.id].id);
        if (item.isVariantProduct) {
          updateVariantProductCallback = () =>
            deleteVariantProductInCache(item.id, item.variantId as string);
        }
      }

      selectedProds.length &&
        deleteProducts(selectedProds, updateVariantProductCallback);
      selectedVars.length && deleteVariants(selectedVars);
      closeModal();
    },
    [
      productItemMaps,
      closeModal,
      deleteProducts,
      deleteVariants,
      deleteVariantProductInCache,
    ],
  );

  const navigateToImportTab = useCallback(() => {
    navigation.navigate('ImportProducts');
  }, [navigation]);

  const onDeleteProduct = useCallback(
    (itemId: string, itemName: string): void => {
      showModal(
        <ConfirmationModal
          title={translate('backOfficeProducts.deletePopUpPromptHeader')}
          message={translate('backOfficeProducts.deletePopUpPromptBody', {
            productName: itemName,
          })}
          onConfirm={(): void => deleteProductItem(itemId)}
        />,
      );
    },
    [showModal, translate, deleteProductItem],
  );

  const updateCategoryEntity = useCallback(
    (response: Product | Variant) => {
      // update product list state to reduce server call
      if (filter.category && filter.category === response.category?.id) {
        setCategoryEntityIds(prev => [...prev, response.id]);
      }
    },
    [filter.category],
  );

  const handleCreateProductRes = useCallback(
    async (
      response: Product | undefined,
      editMore: boolean,
      productForm: CreateProductForm,
    ) => {
      if (!response) {
        setCreatingProduct(productForm);
        return;
      }
      setShowCreateModal(false);
      setCreatingProduct({});
      closeModal();
      // redirect to variant settings for variant
      if (editMore) {
        navigation.navigate('ProductSettings', {
          productId: response.id,
          ...(productForm.isCombo && { screen: 'Options' }),
        });
        return;
      }
      updateCategoryEntity(response);
      const productPricing = getDefaultInfoForProduct(response);
      setProductPricingMaps(prev => ({
        ...prev,
        [response.id]: productPricing,
      }));
    },
    [closeModal, navigation, updateCategoryEntity],
  );

  const handleCreateVariantRes = useCallback(
    (
      response: Variant | undefined,
      editMore: boolean,
      productForm: CreateProductForm,
    ) => {
      if (!response) {
        setCreatingProduct(productForm);
        return;
      }
      setShowCreateModal(false);
      setCreatingProduct({});
      // redirect to variant settings for variant
      if (editMore) {
        navigation.navigate('ProductSettings', {
          productId: response.id,
          isVariant: true,
          screen: 'Variations',
        });
        return;
      }
      updateCategoryEntity(response);
      const products = response.products || [];
      if (!products.length) return;
      // update variant product price to the state when doing copy
      const productPricingMaps = products.reduce((acc, product) => {
        const pricing = getDefaultInfoForProduct(product);
        acc[product.id] = pricing;
        return acc;
      }, {});
      const productMaps = keyBy(products, 'id');
      setRecentCreatedProductVariants(prev => ({
        ...prev,
        ...productMaps,
      }));
      setProductPricingMaps(prev => ({
        ...prev,
        ...productPricingMaps,
      }));
    },
    [navigation, updateCategoryEntity],
  );

  const onCreateVariant = useCallback(
    async (productInput: CreateProductForm, editMore: boolean) => {
      const response = await createVariant({
        name: productInput.name,
        stores: stores.map(x => x.value),
        salesChannels: salesChannels.map(x => x.value),
        category: productInput.category,
      } as CreateVariantProductInput);
      handleCreateVariantRes(response, editMore, productInput);
    },
    [createVariant, handleCreateVariantRes, salesChannels, stores],
  );

  const onConfirmCreate = useCallback(
    async (productInput: CreateProductForm, editMore: boolean) => {
      if (!defaultPricingGroup?.id) {
        showNotification({
          error: true,
          message: translate('productSettings.defaultPricingGroupMissing'),
        });
      }
      if (productInput.isVariant) {
        onCreateVariant(productInput, editMore);
      } else {
        const defaultPricingGroupId = defaultPricingGroup?.id;
        const response = await createProduct({
          name: productInput.name,
          isSellable: true,
          variablePricing: false,
          stores: stores.map(x => x.value),
          salesChannels: salesChannels.map(x => x.value),
          category: productInput?.category,
          ...(productInput.isCombo && {
            productType: productInput.productType,
            isCombo: productInput.isCombo,
            comboType: productInput.comboType,
          }),
          ...(defaultPricingGroupId && {
            productPricing: {
              pricingGroupId: defaultPricingGroup.id,
              sellingPrice: productInput.sellingPrice,
              taxInclusive: productInput.taxInclusive,
              sellingTax: productInput.sellingTax,
            },
          }),
        } as CreateProductInput);

        updateOnboardingStatus(
          OnboardingArea.CATALOGUES,
          OnboardingSection.ADD_A_PAGE_AND_PRODUCT,
          OnboardingCheckList.PRODUCTS,
          OnboardingAction.CREATE,
        );

        handleCreateProductRes(response, editMore, productInput);
      }
    },
    [
      createProduct,
      defaultPricingGroup?.id,
      handleCreateProductRes,
      onCreateVariant,
      salesChannels,
      showNotification,
      stores,
      translate,
      updateOnboardingStatus,
    ],
  );

  const onSelectToggle = useCallback(productId => {
    setProductItemMaps(prev => {
      const tempData = { ...prev };
      const prodOrVariant = prev[productId];
      tempData[productId].isSelected = !prodOrVariant.isSelected;
      if (prodOrVariant.isVariant) {
        // select variant products
        tempData[productId].productIds?.forEach(x => {
          if (tempData?.[x]) {
            tempData[x].isSelected = prodOrVariant.isSelected;
          }
        });
      }
      return tempData;
    });
  }, []);

  const onConfirmCopyProduct = useCallback(
    async (productInput: CreateProductForm, editMore: boolean) => {
      const { name, category = '', sellingPrice, sellingTax } = productInput;
      if (!productInput.isVariant) {
        const response = await copyProduct({
          name,
          category,
          productType: productInput?.productType ?? '',
          sellingPrice,
          originalProductId: productInput.sourceProductId as string,
          sellingTax,
          defaultPricingGroupId: defaultPricingGroup?.id,
        });
        handleCreateProductRes(response, editMore, productInput);
      } else {
        const response = await copyVariant({
          name,
          productType: productInput?.productType ?? '',
          category,
          originalVariantId: productInput?.sourceProductId as string,
        });
        handleCreateVariantRes(response, editMore, productInput);
      }
    },
    [
      copyProduct,
      copyVariant,
      defaultPricingGroup?.id,
      handleCreateProductRes,
      handleCreateVariantRes,
    ],
  );

  const onCreateOrCopyProduct = useCallback(
    (productInput: CreateProductForm, editMore: boolean) => {
      if (productInput.isCopyMode) {
        onConfirmCopyProduct(productInput, editMore);
        return;
      }
      onConfirmCreate(productInput, editMore);
    },
    [onConfirmCopyProduct, onConfirmCreate],
  );

  const onTriggerCopyProduct = useCallback(
    (originalProduct: ProductListItem) => {
      if (originalProduct.isVariant) {
        getVariantData(originalProduct.id);
      } else {
        getProductById(originalProduct.id);
      }

      setCreatingProduct({
        sellingPrice: {
          amount: Number(originalProduct.price),
          currency: originalProduct.currency || Currency.AUD,
        },
        isVariant: originalProduct.isVariant,
        sellingTax: originalProduct.tax,
        isCopyMode: true,
        ...(originalProduct.isCombo &&
          originalProduct.comboType && {
            isCombo: true,
            comboType: originalProduct.comboType,
          }),
        sourceProductId: originalProduct.id,
      });
      setShowCreateModal(true);
    },
    [getProductById, getVariantData],
  );

  const saveProducts = useCallback(() => {
    const updateProductPricingsInput: ProductPricingInput[] = [];
    const productsChanged = changedProducts.filter(x => x && !x.isVariant);

    productsChanged.forEach(eachChangedProd => {
      // update product pricing input
      updateProductPricingsInput.push({
        id: eachChangedProd.defaultPriceId,
        sellingPrice: {
          amount: +eachChangedProd.price,
          currency: currency as Currency,
        } as Money,
        sellingTax: eachChangedProd.tax,
        pricingGroupId: defaultPricingGroup?.id,
        product: eachChangedProd.id,
      } as unknown as ProductPricingInput);
    });

    if (updateProductPricingsInput.length) {
      updateProductPricings(updateProductPricingsInput);
      updateProducts(
        updateProductPricingsInput.map(p => ({
          id: p.product,
          productPricing: getDefaultPricingInput(p),
        })) as UpdateProductInput[],
      );
    }
  }, [
    changedProducts,
    currency,
    defaultPricingGroup,
    updateProductPricings,
    updateProducts,
  ]);

  const onChangeOfItem = useCallback((id: string, key: string, value) => {
    setProductItemMaps(prevMap => {
      const prevMapWithProductId = {
        ...prevMap,
        [id]: { ...prevMap[id], [key]: value, isChanged: true },
      };
      return prevMapWithProductId;
    });
  }, []);

  const onCreateNewProduct = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const onCloseCreateModal = useCallback(() => {
    setShowCreateModal(false);
    setCreatingProduct({});
    closeModal();
  }, [closeModal]);

  const deleteSelectedItems = useCallback(() => {
    const selectedProds = selectedProducts.filter(x => x && !x.isVariant);
    const selectedVars = selectedProducts.filter(x => x && x.isVariant);
    // if variant is deleted then its related variant products must also auto delete
    selectedVars.forEach(eachVar => {
      eachVar.productIds?.forEach(eachProd => {
        productItemMaps?.[eachProd] &&
          selectedProds.push(productItemMaps[eachProd]);
      });
    });
    // making delete product ids unique and deleting
    selectedProds.length &&
      deleteProducts([...new Set(selectedProds.map(x => x && x.id))]);
    selectedVars.length && deleteVariants(selectedVars.map(x => x && x.id));
    closeModal();
  }, [
    selectedProducts,
    deleteProducts,
    deleteVariants,
    closeModal,
    productItemMaps,
  ]);

  useEffect(() => {
    if (!category || category == creatingProduct.category) return;
    // auto set category when create a new product with filter category
    setCreatingProduct(productData => ({
      ...productData,
      category,
    }));
  }, [category, creatingProduct?.category]);

  // copy the product category to the new product

  const sourceProductInfo = useMemo(() => {
    if (!creatingProduct.sourceProductId || creatingProduct.isVariant) return;
    return productDetailMaps[creatingProduct.sourceProductId];
  }, [
    creatingProduct.isVariant,
    creatingProduct.sourceProductId,
    productDetailMaps,
  ]);

  const sourceVariantInfo = useMemo(() => {
    if (!creatingProduct.sourceProductId || !creatingProduct.isVariant) return;
    return variantDetailMaps[creatingProduct.sourceProductId];
  }, [
    creatingProduct.isVariant,
    creatingProduct.sourceProductId,
    variantDetailMaps,
  ]);

  useEffect(() => {
    if (
      (sourceProductInfo || sourceVariantInfo) &&
      (!creatingProduct.category || !creatingProduct.productType)
    ) {
      const sourceCategory =
        sourceProductInfo?.category?.id ||
        (sourceVariantInfo?.category?.id as string);
      const productType =
        sourceProductInfo?.productType?.id ||
        sourceVariantInfo?.productType?.id;
      setCreatingProduct(productData => ({
        ...productData,
        category: sourceCategory,
        productType,
      }));
    }
  }, [
    creatingProduct.category,
    creatingProduct.productType,
    sourceProductInfo,
    sourceVariantInfo,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  const getImportEntityActionBar = useCallback(() => {
    let actionMessage: {
      message: string;
      type: 'positive' | 'neutral' | 'focus' | 'negative';
    };
    if (isImportEntityRunning) {
      actionMessage = {
        type: 'neutral',
        message: translate('importEntity.inProgressMenuMessage'),
      };
    } else if (
      importProductDetailState?.status === IMPORT_ENTITY_STATUS.COMPLETED
    ) {
      actionMessage = {
        type: importProductDetailState.validationMessage?.length
          ? 'negative'
          : 'positive',
        message: importProductDetailState.validationMessage?.length
          ? translate('importEntity.failedMenuMessage', {
              failedProductCount: importProductDetailState.failedCount,
            })
          : translate('importEntity.successMenuMessage', {
              successProductCount: importProductDetailState.successCount,
              updateProductCount: importProductDetailState?.updateCount,
            }),
      };
    } else {
      actionMessage = {
        type: 'negative',
        message: translate('importEntity.invalidMessage'),
      };
    }
    return actionMessage;
  }, [importProductDetailState, isImportEntityRunning, translate]);

  return (
    <ScreenLayout
      title={translate('navigation.products', {
        appName: translate('appName'),
      })}
      onSave={saveProducts}
      hideFooter={!changedProducts.length}
    >
      <LoadingOverlay isLoading={loading && !showCreateModal} />
      <Section layoutWidth="full">
        {importProductDetailState ? (
          <MessageAction
            message={getImportEntityActionBar()?.message}
            type={getImportEntityActionBar()?.type}
            containerStyle={styles.messageActionContainer}
            onPress={navigateToImportTab}
            onPressClose={clearState}
          />
        ) : (
          <></>
        )}
        <View style={styles.filtersContainer}>
          <ProductHeaderFilters
            categories={categoryOptions}
            storeOptions={stores}
            filter={filter}
            onChangeFilter={setFilter}
            onPressImport={navigateToImportTab}
            isImportProductFeatureEnabled={isImportProductFeatureEnabled}
            printerProfileOptions={printerProfileOptions}
            optionsGroupOptions={optionsGroupOptions}
          />
          {(!selectedProducts.length && (
            <CreateButton onPress={onCreateNewProduct} />
          )) || (
            <ProductBulkOptions
              dataMaps={productItemMaps}
              selectedProductListItems={selectedProducts}
              defaultPricingGroupId={defaultPricingGroup?.id}
              taxOptions={taxesOptions}
              productTypeOptions={productTypeOptions}
              printerProfileOptions={printerProfileOptions}
              updateProducts={updateProducts}
              updateVariants={updateVariants}
              storeOptions={stores}
              deleteSelectedItems={deleteSelectedItems}
              categoryOptions={categoryOptions}
              bulkUpdateProductsCategory={bulkUpdateProductsCategory}
              bulkUpdateVariantsCategory={bulkUpdateVariantsCategory}
              updateProductPricings={updateProductPricings}
            />
          )}
        </View>
        {/* Table */}
        {allProductsArray && allProductsArray.length > 0 ? (
          <ProductListView
            data={allProductsArray}
            dataMapper={productItemMaps}
            onCopy={onTriggerCopyProduct}
            onDelete={onDeleteProduct}
            onChangeItem={onChangeOfItem}
            onSelectItem={onSelectToggle}
            onSelectAll={unSelectProducts}
            selectedProducts={selectedProducts}
            page={currentPage}
            onPageChange={setCurrentPage}
          />
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('backOfficeProducts.emptyTable')}
            </Text>
          </View>
        )}
      </Section>
      <ModalWrapper isVisible={showCreateModal}>
        <CreateProductModal
          onCreate={onCreateOrCopyProduct}
          taxesOptions={taxesOptions}
          productTypeOptions={productTypeOptions}
          categoryOptions={categoryOptions}
          createProduct={creatingProduct}
          closeModal={onCloseCreateModal}
          loading={
            prodLoading ||
            varLoading ||
            productDetailLoading ||
            variantDetailLoading
          }
        />
      </ModalWrapper>
    </ScreenLayout>
  );
};
