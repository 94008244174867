import React from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './ReservationsTable.styles';
import Search from '../../../../components/Shared/Search/Search';
import SelectDate from '../../../../components/Shared/Select/SelectDate';
import { format, parseISO } from 'date-fns';
import { ReservationFilters } from '../../../../hooks/app/reservations/useReservations';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import { clearAllOptimisticReservations } from '../optimisticReservationsUtils';

export interface ReservationsHeaderProps {
  onChangeOfFilter: (key: string, value: string) => void;
  onChangeSearch: (value: string) => void;
  filters: ReservationFilters;
  loading: boolean;
  fetchReservations: (fromDate: string, toDate: string) => Promise<void>;
}

const ReservationsHeader: React.FC<ReservationsHeaderProps> = ({
  onChangeOfFilter,
  onChangeSearch,
  filters,
  loading,
  fetchReservations,
}: ReservationsHeaderProps) => {
  const { translate } = useTranslation();

  const handleOnRefresh = () => {
    filters.date && fetchReservations(filters.date, filters.date);
    clearAllOptimisticReservations();
  };

  return (
    <View style={styles.headerContainer}>
      <SelectDate
        testID="select-date"
        valueDate={filters.date ? parseISO(filters.date) : new Date()}
        onChangeDate={date => {
          if (date !== undefined) {
            onChangeOfFilter('date', format(date, 'yyyy-MM-dd'));
          }
        }}
        containerStyle={[styles.dateFilter]}
      />
      <Search
        testID="reservations-search"
        onChangeText={onChangeSearch}
        placeholder={translate('reservations.searchByName')}
        containerStyle={styles.search}
      />
      <ButtonIcon
        testID="btn-refresh"
        size={44}
        icon="sync"
        type="neutral"
        disabled={loading}
        onPress={handleOnRefresh}
      />
    </View>
  );
};

export default ReservationsHeader;
