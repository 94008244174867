/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';

const featuresQuery = `
  id
  name
  enabled
  venueIds
  featureId
`;

export const userDetailQuery = `
  name
  id
  email
  pin
  organizations {
    id
    name
    country
    currencyCode
    logoUrl
    createdAt
    businessIdentifier
    timezone
    features{
      ${featuresQuery}
    }
    onboarding {
      areas {
        name
        sections {
          key
          checklist {
            name
            status
            action
          }
          order
        }
      }
    }
  }
  venues {
    id
    name
    phone
    features{
      ${featuresQuery}
    }
    customerAccountSettings {
      defaultMaxOrderLimit
      defaultMaxBalanceLimit
      scheduleStatements
    }
    address {
      line1
      line2
      city
      suburb
      state
      postalCode
      country
    }
    adjustments {
      id
      name
      amount
      adjustmentUnit
      adjustmentType
      allowOnPaymentType
      order
      schedule {
        day
        isActive
        timeSlot {
          startTime
          endTime
        }
      }
      orderTypes
      stores
      doNotIncludeInSalesAmount
      advanceRules {
        applyOn
        guestCount
        purchasedCriteria {
          operator
          productIds
          categoryIds
          qty
        }
        discountedCriteria {
          operator
          productIds
          categoryIds
          qty
        }
      }
      dateTimeRange {
        startDate
        endDate
        startTime
        endTime
      }
      isDateRangeEnabled
      isScheduledEnabled
      isAdvanceRuleEnabled
      isDiscountRuleEnabled
      discountRuleType
      autoApply
      displayNameOnReceipt
      adjustmentCode
    }
    stores {
      id
      name
      businessIdentifier
      phoneNumber
      address {
        line1
        line2
        city
        suburb
        state
        postalCode
        country
      }
      checkoutOptions {
        enableRoundOff
        enableTips
        roundOffValue
      }
      deviceProfiles {
        id
        name
        defaultSaleScreen
        postSaleScreen
        enableCashManagement
        enableFloorView
        enableAllergens
        enableCourses
        orderIdentifier
        promptGuestCount
        takingOrders
        payAtTable
        enableSeatManagement
        enableHideZeroPrices
        enableComplimentaryMode
        enableOnlineOrders
        enableQRPrint
        enableBarcodeScan
        enableKitchenBuzzer
        receiptPrintOption
        showPrinterProfileInDocket
        printZeroValueItems
        printZeroValueModifiers
        printNotes
        isDefault
        status
        menu {
          id
          name
        }
        orderTypes {
          id
          name
          code
        }
        defaultOrderType
        {
          id
          name
          code
        }
        functionMap {
          id
          name
          functionActions {
            id
            name
            action
            params
            isGroup
            groupId
            color
            order
            feature
            featureContext
          }
        }
        sections {
          id
          name
          defaultPrefix
          defaultTableShape
          isActive
          tableCount
          tables {
            id
            name
            shape
            guestCount
            horizontalSeats
            verticalSeats
            position {
              top
              left
            }
          }
        }
      }
      devices {
        id
        name
        uuid
        details
        salesPrefix
        returnPrefix
        deviceCode
        cashDrawer
        trainingMode
        paymentTerminal {
          id
          name
          uuid
        }
        previousOrder {
          orderNumber
        }
        appVersion
        deviceProfile {
          id
        }
        customerDisplay {
          id
          name
          isPaired
          connectedPosDevice {
            deviceProfile {
              orgLogo
              backgroundImage
              promoImages
            }
          }
          lastLoginAt
        }
        isTokenNumberActive
        tokenSettings {
          tokenRange {
            start
            end
          }
          resetTokenNumberOnShiftClosure
          onlyPrintTokenOnReceipts
        }
        printingOptions {
          profile {
            id
            name
            locale
            orderTypes
            singleItemPrinting
          }
          printer {
            id
            name
            ipAddress
            bdAddress
            slug
            series
            brand
            printerType
            isActive
          }
          printerProfileType
          template {
            template
            isDeleted
            id
            organization
            name
            templateType
            isBaseTemplate
            headerText
            footerText
            logoURL
            socialLinks {
              facebook
              twitter
              instagram
              website
            }
            oolioBranding
            showABN
            showTaxInvoice
            showLoyaltyEnrolmentQR
            loyaltyEnrolmentTitle
          }
        }
        currentTokenNumber
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      user {
        ${userDetailQuery}
      }
      token
      refreshToken
      expiresIn
    }
  }
`;

export const GET_USER_QUERY = gql`
  query getUserDetail {
    me {
        ${userDetailQuery}
      }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logout($token: String!) {
    logout(token: $token) {
      success
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      success
      message
    }
  }
`;

export const GET_APP_TOKEN = gql`
  mutation clientAppToken($source: App!) {
    clientAppToken(source: $source) {
      token
    }
  }
`;

export const LOGIN_BY_DEVICE_CODE_MUTATION = gql`
  mutation loginByDeviceCode($deviceCodeInput: DeviceCodeLoginInput!) {
    loginByDeviceCode(input: $deviceCodeInput) {
      appToken
      organization
      store
      deviceId
      venue
      users {
        ${userDetailQuery}
      }
    }
  }
`;
