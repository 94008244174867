import { StyleSheet, Platform } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  headerContainer: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
  },
  search: {
    flex: 1,
  },
  dateFilter: {
    width: 180,
    zIndex: 4,
  },
  btnRefresh: {
    marginHorizontal: 10,
  },
  tableHeader: {
    height: 38,
    marginHorizontal: 20,
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    paddingHorizontal: 12,
  },
  tableBody: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
  tableRow: {
    height: 66,
    marginBottom: 4,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  columnIcon: {
    width: 70,
  },
  cellIcon: {
    width: 70,
    textAlign: 'center',
  },
  icon: {
    height: 28,
    width: 28,
    borderRadius: theme.radius.s,
    marginLeft: 10,
  },
  columnGuests: {
    width: 70,
  },
  cellGuests: {
    width: 40,
    textAlign: 'center',
  },
  columnTable: {
    width: 80,
    paddingHorizontal: 6,
  },
  cellTableNo: {
    width: 80,
    marginBottom: 4,
  },
  cellTableArea: {
    width: 100,
    color: theme.colors.grey6,
  },
  columnCustomer: {
    width: 140,
    paddingHorizontal: 6,
  },
  cellCustomerName: {
    width: 140,
    marginBottom: 4,
  },
  cellCustomerPhone: {
    width: 140,
    color: theme.colors.grey6,
  },
  columnReservationTime: {
    width: 180,
    paddingHorizontal: 6,
  },
  cellReservationTimeDate: {
    width: 160,
    marginBottom: 4,
  },
  cellReservationTimeRange: {
    width: 160,
    color: theme.colors.grey6,
  },
  columnNotes: {
    flex: 1,
  },
  cellReservationNotes: {
    width: 330,
    marginBottom: 4,
  },
  cellCustomerNotes: {
    width: 330,
    color: theme.colors.grey6,
  },
  columnStatus: {
    marginRight: 10,
  },
  cellStatus: {
    width: Platform.select({
      ios: 110,
    }),
  },
});

export default styles;
