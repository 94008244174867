import React, { useState, useMemo } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import {
  Dictionary,
  DiscountType,
  EXCLUDED_ITEM_STATUSES,
  OrderItem,
  Product,
  RewardByDiscountProduct,
  RewardRule,
  RewardType,
  Order,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import {
  RewardMap,
  UseRewardsInterface,
} from '../../../../hooks/orders/useRewards';
import theme from '../../../../common/default-theme';
import { styles } from './RedeemItemsModal.styles';
import Icon from '../../../Icon/Icon';
import Search from '../../../Shared/Search/Search';
import Counter from '../../../Shared/Counter/Counter';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import { useOolioLoyalty } from '../../../../hooks/useOolioLoyalty';

const TYPE_WITH_ITEM_QUANTITY: RewardType[] = [RewardType.FREE_ITEM];

export interface RedeemItemsModalProps {
  onBack: (rewardMap: RewardMap) => void;
  onRedeem: ReturnType<UseRewardsInterface>['redeemRewards'];
  orderItems?: OrderItem[];
  remainingBalance: number;
  reward: RewardRule & Required<{ products: Product[] }>;
  rewardMap: RewardMap;
  currentRewardMap: RewardMap;
  allRewards: Array<RewardRule>;
  order?: Order;
}

const RedeemItemsModal: React.FC<RedeemItemsModalProps> = ({
  onRedeem,
  onBack,
  rewardMap,
  reward,
  remainingBalance,
  orderItems,
  currentRewardMap,
  allRewards,
  order,
}) => {
  const { translate } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [appliedRewardMap, setAppliedRewardMap] =
    useState<RewardMap>(rewardMap);

  const { isLoyaltyEnabled: isNewLoyaltyEnabled } = useOolioLoyalty();

  const redemptionMap = useMemo(() => {
    return (appliedRewardMap[reward.id] || []).reduce(
      (map, redemption) => {
        const productId = redemption.productId as string;
        const newRedemption = redemption;

        if (redemption.itemQuantity) {
          // sum item quantity
          newRedemption.itemQuantity =
            (map[productId]?.itemQuantity || 0) + redemption.itemQuantity;
        }

        map[productId] = newRedemption;
        return map;
      },
      {} as Dictionary<{
        quantity: number;
        points: number;
        type: RewardType;
        productId?: string;
        itemQuantity?: number;
      }>,
    );
  }, [appliedRewardMap, reward]);

  const shouldDisableIncrement = (productId: string): boolean => {
    // previous consumption, calculated from the original rewardMap
    const previousConsumption = (rewardMap[reward.id] || []).reduce(
      (total, redemption) =>
        total +
        redemption.points * (redemption.itemQuantity || redemption.quantity),
      0,
    );

    const totalQuantity =
      orderItems
        ?.filter(
          item =>
            (item.product.id === productId || item.variant?.id === productId) &&
            !EXCLUDED_ITEM_STATUSES.includes(item.status),
        )
        .reduce((total, item) => total + item.quantity, 0) || 0;

    const reachedMaxQuantity = reward.maxRedemptionPerOrder
      ? (redemptionMap[productId]?.itemQuantity ||
          redemptionMap[productId]?.quantity) === reward.maxRedemptionPerOrder
      : false;

    return (
      // Adjustment.itemQuantity equals item's quantity
      Number(redemptionMap[productId]?.itemQuantity) >= totalQuantity ||
      // balance insufficient
      (appliedRewardMap[reward.id] || []).reduce(
        (acc, redemption) =>
          acc -
          (redemption.itemQuantity || redemption.quantity) * redemption.points,
        remainingBalance + previousConsumption, // accounted with previous consumption
      ) -
        reward.pointsRequired <
        0 ||
      // max discount applied
      isMaximumDiscountAmountReached(
        reward as RewardByDiscountProduct,
        orderItems ?? [],
        appliedRewardMap,
      ) ||
      reachedMaxQuantity
    );
  };

  const shouldDisableDecrement = (productId: string): boolean => {
    return !redemptionMap[productId] || redemptionMap[productId].quantity === 0;
  };

  const processQuantityChange = (quantity: number) => {
    // update itemQuantity for percentage discount and free item only
    return ('discountType' in reward &&
      reward.discountType === DiscountType.PERCENTAGE) ||
      TYPE_WITH_ITEM_QUANTITY.includes(reward.rewardType)
      ? { quantity: quantity && 1, itemQuantity: quantity } // itemQuantity gets updated, quantity stays as 0 or 1
      : { quantity }; // quantity get updated
  };

  function isMaximumDiscountAmountReached(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reward: any | RewardByDiscountProduct,
    orderItems: OrderItem[],
    appliedRewardMap: RewardMap,
  ): boolean {
    const { discountAmount, maximumDiscountAmount, discountType } = reward;
    const appliedRewards = appliedRewardMap[reward.id];
    let maximumDiscountReached = false;

    if (discountType === DiscountType.PERCENTAGE) {
      if (!appliedRewards || !maximumDiscountAmount) {
        return false;
      }

      let totalDiscountApplied = 0;

      if (appliedRewards) {
        for (const appliedReward of appliedRewards) {
          const matchingItems = orderItems
            .filter(
              item =>
                item.product.id === appliedReward.productId ||
                item.variant?.id === appliedReward.productId,
            )
            ?.slice(0, appliedReward.itemQuantity ?? 1);

          matchingItems.forEach(matchingItem => {
            let basePrice = 0;
            basePrice = matchingItem.unitPrice;
            if (matchingItem.modifiers && matchingItem.modifiers.length > 0) {
              matchingItem.modifiers.forEach(modifier => {
                basePrice += modifier.unitPrice;
              });
            }
            if (matchingItem.quantity > 1) {
              basePrice =
                matchingItem.unitPrice * (appliedReward?.itemQuantity ?? 1);
            }
            totalDiscountApplied += (basePrice * discountAmount) / 100;
            const potentialDiscount =
              (matchingItem.unitPrice * discountAmount) / 100;

            if (
              totalDiscountApplied + potentialDiscount >
              maximumDiscountAmount
            ) {
              maximumDiscountReached = true;
              return true;
            }
          });
        }
      }

      if (maximumDiscountReached) {
        return true;
      }

      return totalDiscountApplied >= maximumDiscountAmount;
    }

    return false;
  }

  const handleChange = (productId: string, quantity: number) => {
    setAppliedRewardMap(previousMap => {
      return {
        ...previousMap,
        [reward.id]: Object.values({
          ...redemptionMap,
          [productId]: {
            points: reward.pointsRequired,
            productId: productId,
            type: reward.rewardType,
            ...processQuantityChange(quantity),
          },
        }),
      };
    });
  };

  const filteredProducts = useMemo(() => {
    const cartItems = orderItems?.map(item =>
      isNewLoyaltyEnabled && item.variant?.id
        ? item.variant?.id
        : item.product.id,
    );

    const redeemableItems = reward.products.filter(product =>
      cartItems?.includes(product.id),
    );

    return redeemableItems.filter(x =>
      x?.name?.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [orderItems, reward.products, searchText, isNewLoyaltyEnabled]);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-back"
          style={styles.btnBack}
          onPress={() => onBack(appliedRewardMap)}
        >
          <Icon name="arrow-left" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {translate('customerLoyalty.selectItemsToRedeem')}
        </Text>
      </View>
      <View style={styles.content}>
        <Search
          testID="search-products"
          value={searchText}
          onChangeText={setSearchText}
          placeholder="Search products by name to filter..."
        />
        <ScrollView style={styles.productSection}>
          <View style={styles.products}>
            {filteredProducts.length > 0 ? (
              filteredProducts.map(product => {
                const isSelected = redemptionMap[product.id]?.quantity > 0;
                const disableDecrease = shouldDisableDecrement(product.id);
                const disableIncrease = shouldDisableIncrement(product.id);

                return (
                  <View
                    key={product.id}
                    testID="product-item"
                    style={[styles.row, isSelected && styles.selected]}
                  >
                    <Text style={styles.text}>{product.name}</Text>
                    <Counter
                      value={
                        redemptionMap[product.id]?.itemQuantity ||
                        redemptionMap[product.id]?.quantity ||
                        0
                      }
                      decrease={{
                        disabled: disableDecrease,
                        onPress: () =>
                          handleChange(
                            product.id,
                            (redemptionMap[product.id]?.itemQuantity ||
                              redemptionMap[product.id]?.quantity) - 1,
                          ),
                      }}
                      increase={{
                        disabled: disableIncrease,
                        onPress: () =>
                          handleChange(
                            product.id,
                            (redemptionMap[product.id]?.itemQuantity ||
                              redemptionMap[product.id]?.quantity ||
                              0) + 1,
                          ),
                      }}
                    />
                  </View>
                );
              })
            ) : (
              <View style={theme.tables.emptyView}>
                <Text
                  style={theme.tables.emptyText}
                >{`No results matching "${searchText}" were found.`}</Text>
              </View>
            )}
          </View>
        </ScrollView>
        <TreatButton
          testID="redeem-btn"
          type="positive"
          label={translate('customerLoyalty.redeemItems')}
          onPress={() => {
            onRedeem(currentRewardMap, appliedRewardMap, allRewards, order);
          }}
        />
      </View>
    </View>
  );
};

export default RedeemItemsModal;
