import { gql } from '@apollo/client';

const modifierQueryFragment = `
  id
  name
  plu
  modifierGroups {
    id
  }
  pricingGroups {
    id
    name
    prices {
      id
      sellingPrice{
        amount
        currency
      }
      sellingTax {
        id
        name
      }
    }
  }
  price {
    amount
    currency
  }
  isDefault
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;

export const GET_MODIFIER_QUERY = gql`
  query modifierData($id: ID!) {
    modifier(id: $id) {
      ${modifierQueryFragment}
    }
  }
`;

export const GET_MODIFIERS_QUERY = gql`
  query modifiersData {
    modifiers {
      ${modifierQueryFragment}
    }
  }
`;

export const CREATE_MODIFIERS = gql`
  mutation createModifiers($input: [CreateModifierInput!]!) {
    createModifiers(input: $input) {
      ${modifierQueryFragment}
    }
  }
`;

export const UPDATE_MODIFIERS = gql`
  mutation updateModifiers($input: [UpdateModifierInput!]!) {
    updateModifiers(input: $input) {
      ${modifierQueryFragment}
    }
  }
`;

export const DELETE_MODIFIERS = gql`
  mutation deleteModifiers($ids: [ID!]!) {
    deleteModifiers(ids: $ids)
  }
`;
