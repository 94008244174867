import React, { useMemo, useCallback, useState } from 'react';
import { View } from 'react-native';
import { keyBy } from 'lodash';
import { Table } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../../../hooks/app/useSession';
import { TableAction, transferTablesController } from '../floorViewObservables';
import { useTablesData } from '../../../../../../hooks/app/tables/useTablesData';
import { noopHandler } from '../../../../../../utils/errorHandlers';
import { sortTablesByName } from '../../../../../../utils/TableHelper';
import styles from './SelectTableModal.styles';
import { MemoListTableIcons } from '../SectionsView';
import DraggableTableIcon from '../Tables/DraggableTableIcon';
import ModalTitle from '../../../../../../components/POS/Modals/ModalTitle/ModalTitle';
import SectionTabs from '../../../../../../components/POS/SectionTabs/SectionTabs';

interface SelectTableModalProps {
  onSelection: (table: Table, orderId?: string) => void;
  onClose?: () => void;
  canSelectSubTable?: boolean;
}

const SelectTableModal: React.FC<SelectTableModalProps> = ({
  onSelection,
  onClose,
  canSelectSubTable,
}: SelectTableModalProps) => {
  const { translate } = useTranslation();
  const [{ deviceProfile }] = useSession();
  const { tableStatusMap, tableOrdersMap, upcomingReservationsMap } =
    useTablesData({ shouldTrackReservations: true });

  const sectionsOptions = useMemo(
    () =>
      deviceProfile?.sections?.map(x => ({
        label: x.name,
        value: x.id,
      })) ?? [],
    [deviceProfile?.sections],
  );

  const initialSelection = sectionsOptions?.[0]?.value ?? '';
  const [selectedSection, setSelectedSection] = useState(initialSelection);

  const allSectionsMap = useMemo(
    () => keyBy(deviceProfile?.sections ?? [], 'id'),
    [deviceProfile?.sections],
  );

  const allTables = useMemo(
    () =>
      sortTablesByName(allSectionsMap[selectedSection]?.tables ?? []).map(
        table =>
          ({
            ...table,
            status: tableStatusMap[table.id],
            section: {
              id: selectedSection,
            },
            upcomingReservation: upcomingReservationsMap[table?.name] || null,
          } as Table),
      ),
    [allSectionsMap, selectedSection, tableStatusMap, upcomingReservationsMap],
  );

  const positionedTables = useMemo<Table[]>(
    () =>
      allTables.filter(
        table =>
          typeof table.position?.top === 'number' &&
          typeof table.position?.left === 'number',
      ),
    [allTables],
  );

  const unPositionedTables = useMemo<Table[]>(
    () =>
      allTables.filter(
        table =>
          typeof table.position?.top !== 'number' ||
          typeof table.position?.left !== 'number',
      ),
    [allTables],
  );

  const displayAsGrid = useMemo<boolean>(
    () => unPositionedTables.length === allTables.length,
    [allTables, unPositionedTables],
  );

  const onChangeSection = useCallback(sectionId => {
    setSelectedSection(sectionId);
  }, []);

  const onSelectTable = useCallback(
    (table: Table) => {
      const orders = tableOrdersMap[table.id] ?? [];
      const isMultiOrderTable = orders?.length > 1;
      const isOrderTableTransfer = transferTablesController.getValue();
      if (isMultiOrderTable && !isOrderTableTransfer) return;

      table.section.name = allSectionsMap[selectedSection]?.name;
      const activeOrder = orders?.[0];
      onSelection(table, activeOrder?.id);
    },
    [allSectionsMap, onSelection, selectedSection, tableOrdersMap],
  );

  const onSelectSubTable = useCallback(
    (table: Table, orderIndex?: number) => {
      const orders = tableOrdersMap[table.id] ?? [];
      table.section.name = allSectionsMap[selectedSection]?.name;
      onSelection(table, orders[orderIndex ?? 0]?.id);
    },
    [allSectionsMap, onSelection, selectedSection, tableOrdersMap],
  );

  const renderTableInLayout = useCallback(
    (tableObj: Table, index: number) => {
      const table = { ...tableObj, status: tableStatusMap[tableObj.id] };
      const orders = tableOrdersMap[table.id] ?? [];

      return (
        <DraggableTableIcon
          key={table.id}
          onRequestClose={noopHandler}
          orders={orders}
          table={table}
          onPressSubTable={onSelectSubTable}
          index={index}
          onPressIn={noopHandler}
          onDragTable={noopHandler}
          onDragReleaseTable={noopHandler}
          isDraggingTable={false}
          onPressTable={onSelectTable}
          displayAsGrid={displayAsGrid}
          tableAction={
            canSelectSubTable ? TableAction.DEFAULT : TableAction.MERGE
          }
        />
      );
    },
    [
      tableStatusMap,
      tableOrdersMap,
      onSelectSubTable,
      onSelectTable,
      displayAsGrid,
      canSelectSubTable,
    ],
  );

  return (
    <View style={styles.container}>
      <ModalTitle
        onDismiss={onClose}
        title={translate('tableFloorView.selectTable')}
      />
      <View style={styles.content}>
        <View style={styles.floor}>
          <MemoListTableIcons
            list={positionedTables}
            renderer={renderTableInLayout}
          />
          <MemoListTableIcons
            list={unPositionedTables}
            renderer={renderTableInLayout}
          />
        </View>
        <SectionTabs
          tabs={sectionsOptions}
          testID="segments-orderScreens"
          selectedTab={selectedSection}
          onPress={onChangeSection}
        />
      </View>
    </View>
  );
};

export default SelectTableModal;
