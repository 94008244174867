import { ProductPricingInput } from '@oolio-group/domain';

export const getDefaultPricingInput = (
  pricingInput: ProductPricingInput,
): ProductPricingInput => {
  if (!pricingInput) return {} as ProductPricingInput;
  return {
    pricingGroupId: pricingInput.pricingGroupId,
    sellingPrice: {
      ...pricingInput.sellingPrice,
      amount: +(pricingInput.sellingPrice.amount || 0),
    },
    sellingTax: pricingInput.sellingTax,
    ...(pricingInput.costPrice && {
      costPrice: {
        ...pricingInput.costPrice,
        amount: +(pricingInput.costPrice.amount || 0),
      },
    }),
    ...(pricingInput.costTax && { costTax: pricingInput.costTax }),
    taxInclusive: pricingInput.taxInclusive,
  } as ProductPricingInput;
};
