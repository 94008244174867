import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RESERVATION_STATUS, Reservation } from '@oolio-group/domain';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import { View } from 'react-native';
import {
  ReservationFilters,
  useReservations,
} from '../../../../hooks/app/reservations/useReservations';
import { styles } from '../Reservations.style';
import { useNavigation } from '@react-navigation/native';
import theme from '../../../../common/default-theme';
import ReservationsHeader from '../Table/ReservationsHeader';
import ReservationsTable from '../Table/ReservationsTable';
import { format, isToday } from 'date-fns';
import { debounce } from 'lodash';
import ReservationSidePanel from '../../../../components/POS/SidePanels/ReservationSidePanel';
import { useNotification } from '../../../../hooks/Notification';
import { useTodayReservationsWithOptimisticUpdates } from '../../../../hooks/app/reservations/useOptimisticReservations';

const List: React.FC = ({}) => {
  const {
    reservations,
    filterReservationsData,
    fetchReservations,
    setReservations,
    loading,
    error,
  } = useReservations();

  const navigation = useNavigation();
  const safeHeight = theme.useSafeHeight();

  const { showNotification } = useNotification();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [details, setDetails] = useState<Reservation>({} as Reservation);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setFilters] = useState<ReservationFilters>({
    date: format(new Date(), 'yyyy-MM-dd'),
    searchText: '',
    statuses: [
      RESERVATION_STATUS.NOT_RECONCILED,
      RESERVATION_STATUS.BOOKED,
      RESERVATION_STATUS.CONFIRMED,
      RESERVATION_STATUS.ARRIVED,
      RESERVATION_STATUS.PARTIALLY_ARRIVED,
      RESERVATION_STATUS.PRE_ARRIVED,
      RESERVATION_STATUS.PRE_PARTIALLY_ARRIVED,
    ],
  });
  const todayReservations = useTodayReservationsWithOptimisticUpdates();

  useMemo(() => {
    if (isToday(new Date(filters.date || 0))) {
      setReservations(todayReservations);
    }
  }, [todayReservations, filters.date, setReservations]);

  useEffect(() => {
    filters.date && fetchReservations(filters.date, filters.date);
  }, [fetchReservations, filters.date]);

  const filteredReservations = useMemo(
    () =>
      filterReservationsData(reservations, {
        statuses: filters.statuses,
        searchText: filters.searchText,
      })
        .filter(
          reservation =>
            reservation.status_display.toLowerCase() !==
            RESERVATION_STATUS.SEATED.toLowerCase(),
        )
        .sort(
          (a, b) =>
            new Date(a.real_datetime_of_slot).getTime() -
            new Date(b.real_datetime_of_slot).getTime(),
        ),
    [
      filterReservationsData,
      filters.statuses,
      filters.searchText,
      reservations,
    ],
  );

  const onDrawerClose = (): void => {
    setOpenDrawer(false);
  };

  const onChangeOfFilter = useCallback((key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setCurrentPage(1);
  }, []);

  const debouncedSearch = React.useRef(
    debounce(async (searchText: string) => {
      onChangeOfFilter('searchText', searchText);
    }, 300),
  ).current;

  const onChangeSearch = useCallback(
    (searchText: string) => {
      debouncedSearch(searchText);
      setCurrentPage(1);
    },
    [debouncedSearch],
  );
  const onSelectReservation = useCallback(details => {
    setDetails(details);
    setOpenDrawer(true);
  }, []);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  return (
    <ScreenLayout title="Reservations" onBack={() => navigation.goBack()}>
      <View style={(styles.reservationContainer, { height: safeHeight })}>
        <ReservationsHeader
          filters={filters}
          onChangeOfFilter={onChangeOfFilter}
          onChangeSearch={onChangeSearch}
          loading={loading}
          fetchReservations={fetchReservations}
        />
        <ReservationsTable
          data={filteredReservations}
          onSelectReservation={onSelectReservation}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <ReservationSidePanel
          onClose={onDrawerClose}
          reservation={details}
          showPanel={openDrawer}
          selectedDate={filters.date}
          setReservations={setReservations}
        />
      </View>
    </ScreenLayout>
  );
};

export default List;
