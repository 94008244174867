import { Platform, StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';
import { BookingStatusEnum } from '@oolio-group/domain';

const textStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.medium,
  fontVariant: ['tabular-nums'],
};

export const styles = StyleSheet.create({
  container: {
    rowGap: 4,
  },
  header: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 12,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  body: {
    rowGap: 4,
  },
  row: {
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  stickerPosition: {
    width: 50,
    height: 26,
    marginRight: 10,
  },
  cellName: {
    width: 150,
    ...textStyle,
  },
  cellPhone: {
    width: 130,
    ...textStyle,
    marginLeft: 20,
  },
  cellCreated: {
    flex: 1,
    ...textStyle,
    marginLeft: 20,
  },
  cellStatus: {
    width: Platform.select({
      ios: 110,
    }),
  },
  disclosure: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
});

export const getStatusStickerType = (status: BookingStatusEnum) => {
  switch (status) {
    case BookingStatusEnum.COMPLETED:
      return 'positive';
    case BookingStatusEnum.CHECKED_IN:
      return 'positiveLight';
    case BookingStatusEnum.READY:
      return 'neutral';
    case BookingStatusEnum.NO_SHOW:
      return 'negativeLight';
    case BookingStatusEnum.CANCELLED:
      return 'negative';
    default:
      return 'cancel';
  }
};

export const getPositionStickerType = (position: number) => {
  if (position <= 2) {
    return 'positiveLight';
  } else if (position <= 6) {
    return 'neutralLight';
  } else if (position <= 10) {
    return 'focusLight';
  } else {
    return 'negativeLight';
  }
};
